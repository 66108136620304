import React from 'react'
import { NavbarUser } from '../components/navbars/NavbarUser'
import { GridPiecesWithDefectss } from '../components/grids/GridPiecesWithDefectss'

export const UserFeedBack = () => {
  return (
    <div>
        <NavbarUser/>
        <GridPiecesWithDefectss/>
    </div>
  )
}
