import React, { useEffect, useState } from 'react'
import { major, serve, stullerDefault } from '../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { closeSesion, fetchGet, getCookie } from '../../utils/functions';
import { UserOptions } from './UserOptions';

export const NavbarMajor = () => {
    const [ name, setName ] = useState('');
    const [ image, setImage ] = useState('');
    const navigate  = useNavigate();
    const id = getCookie('id');

    const validate = async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.validateUser + id});
        if(data.data.data.role != 300){navigate('/')}
        setName(data.data.data.name);
        setImage(data.data.data.image);
    };

    useEffect(() => {
        if(typeof(id) == 'undefined'){
            navigate('/')
        }
        if(typeof(id) == 'string'){
            validate();
        }
    }, []);

    return (
      <nav>
          <div>
              <div className='logos'>
                  <img src={serve}/>
                  <p>MAESTRO JOYERO ORFEBRE</p>
              </div>
  
              <div className='buttons'>
                    <div onClick={() => navigate('/major/admin')}>Administración</div>
                    <div onClick={() => navigate('/major/departments')}>Departamentos</div>
                    <div onClick={() => navigate('/major/majors')}>MAJORS</div>

                    <UserOptions image={image} name={name}/>
              </div>
          </div>
      </nav>
    )
}
