import React from 'react'
import { useNavigate } from 'react-router-dom';
import { TablePaginate } from './TablePaginate';
import { baseURL } from '../../utils/constants/apis/major';
import { stullerDefault } from '../../utils/constants';
import { setCookie } from '../../utils/functions';

export const TableDefects = ({ data, updateTable }) => {
    const navigate = useNavigate();

    function cookieAndGo(name, cookieValie, route){
        setCookie({ name: name, value: cookieValie })
        navigate(route);
    }

    
    return (
        <TablePaginate
            rows={data.map((defect, index) => (
                <tr key={index}>
                    <td><img src={defect.image ? baseURL + defect.image : stullerDefault}/></td>
                    <td>{defect.name}</td>
                    <td>{defect.operation}</td>
                    <td>{defect.level}</td>
                    <td>{defect.sublevel}</td>

                    <td>
                        <button onClick={(e) =>cookieAndGo('defectId', defect.id, '/admin/defects/edit')} className='edit'>Editar</button>
                    </td>
                </tr>
            ))}

            columns={['Imagen', 'Nombre', 'Operacion', 'Nivel', 'Subnivel', 'Acciones']}
            functionData={updateTable}
        />
    )
}
