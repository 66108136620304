import React from 'react'
import { NavbarMajor } from '../components/navbars/NavbarMajor'
import { TableMajorsAdmin } from '../components/tables/TableMajorsAdmin'

export const MajorInfo = () => {
  return (
    <div>
        <NavbarMajor/>
        <TableMajorsAdmin/>
    </div>
  )
}
