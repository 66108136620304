import React, { useState } from 'react'
import { fetchGet } from '../../utils/functions';
import { major } from '../../utils/constants';
import { TablePaginate } from './TablePaginate';

export const TableMajorsAdmin = () => {
    const [ admins, setAdmins ] = useState([]);

    const getAdmins = async (page, filter='') => {
        const pag = '?page=' + page;
        const { data, loading, error } = await fetchGet({
            endpoint: major.getMajorAdministrations + pag,
            params:{"filter": filter}
        });
        setAdmins(data.data.data.data);
        return data;
    };

    return (
        <TablePaginate
        rows={admins.map((admin, index) => (
            <tr key={index}>
                <td>{admin.number}</td>
                <td>{admin.name}</td>
                <td>{admin.status}</td>
                <td>{admin.entrys_date}</td>
                <td>{admin.area}</td>
                <td>{admin.gender}</td>
                <td>{admin.schedule}</td>
                <td>{admin.departments_number}</td>
                <td>{admin.department}</td>
            </tr>
        ))}

        columns={['#', 'Nombre', 'Status', 'F. Ingreso', 'Área laboral', 'Sexo', 'Turno', 'No. Depto Conta', 'Departamento']}
        functionData={getAdmins}
    />
    )
}
