import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { ViewAdminCourses } from '../components/views/ViewAdminCourses'

export const Courses = () => {
  return (
    <div>
        <NavbarAdmin/>
        <ViewAdminCourses/>
    </div>
  )
}
