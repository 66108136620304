import React, { useEffect, useState } from 'react'
import { clicked, fetchGet, fetchPut, getCookie } from '../../utils/functions'
import { major, stullerDefault } from '../../utils/constants';
import { imagePreview } from '../../utils/functions/imagePreview';

export const FormEditDefect = () => {
    const [ name, setName ] = useState('')
    const [ link, setLink ] = useState('');
    const [ image, setImage ] = useState('');
    const [ preview, setPreview ] = useState('');
    const defectId = getCookie('defectId');

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(file);  
        const prev = imagePreview(event);
        setPreview(prev);   
    };
    
    const getDefect = async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.getDefect + defectId});
        setImage(data.data.data.image);
        if(data.data.data.image){setPreview(major.baseURL + data.data.data.image);}
        setName(data.data.data.name);
        setLink(data.data.data.watch);
      };

    const FormUpdateDefect = async (e) => {
        e.preventDefault();
    
        const { data, loading, error } = 
        await fetchPut({
            endpoint: major.updateDefect + defectId,
            params:{
              "name": name,
              "link": link,
              "image": image
            },
            headers:{"Content-Type" : "multipart/form-data"}
        });
    };

    useEffect(() => {
        if(defectId != null){getDefect();};
      }, []);

  return (
    <div className='form-flex'>    
          <form >
            <h2>EDITAR USUARIO</h2>

              <img src={preview || stullerDefault}  onClick={() => clicked('image_admin_defect')}/>
              <input id='image_admin_defect' type='file' onChange={handleImageChange} style={{ display: 'none' }}/>
              
              <input value={name} placeholder='Nombre' onChange={(e) => (setName(e.target.value))}/>
              <input value={link} placeholder='Link' onChange={(e) => (setLink(e.target.value))}/>

              <button type='submit' onClick={(e) => FormUpdateDefect(e)}>Editar</button>
          </form>
      </div>
  )
}
