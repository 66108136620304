import React from 'react'
import { NavbarUser } from '../components/navbars/NavbarUser'
import { GridClasses } from '../components/grids/GridClasses';


export const Classes = () => {
    return (
        <div>
             <NavbarUser/>
             <GridClasses/>
        </div>
    )
}
