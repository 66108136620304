import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { ViewAdminContents } from '../components/views/ViewAdminContents'

export const AdminContents = () => {
  return (
    <div>
      <NavbarAdmin/>
      <ViewAdminContents/>
    </div>
  )
}
