import React, { useEffect, useState } from 'react'
import { fetchGet, getCookie, modal, setCookie } from '../../utils/functions';
import { major } from '../../utils/constants';
import { baseURL } from '../../utils/constants/apis/major';
import { ModalRate } from '../modals/ModalRate';

export const GridDistribution = () => {
    const [ trainers, setTrainers ] = useState([]);
    const idTrainer = getCookie('id');
    const [ showUserRate, setShowUserRate ] = useState('17');
    const [ showUserNameRate, setShowUserNameRate ] = useState('');

    const getDistribution = async () => {
        const { data } = await fetchGet({endpoint: major.getDistribution});
        setTrainers(data.data.data);
        data.data.data.forEach(item => {if(item.id == idTrainer){setTrainers([item]);}});
    };

    function goStats(id, name){
        setShowUserRate(id);
        setShowUserNameRate(name);
        modal('modal-rate')
    }

    useEffect(() => {
        getDistribution();
    }, []);

    return (
        <>
        <div className='grid' id='distributions'>
            {trainers.map((trainer, index) => (
                <div key={index} className='distribution'>

                    <div className='trainer'>
                        <img src={baseURL + trainer.image} />
                        <div className='data'>
                            <h2>{trainer.name + ' ' + trainer.paternal_surename + ' ' + trainer.maternal_surename}</h2>
                            <h3>{trainer.operation}</h3>
                            <p>{trainer.sublevel} {trainer.start} - {trainer.end}</p>
                        </div>
                        <div className='capacity'>
                            <p>{trainer.current} / {trainer.capacity}</p>
                        </div>
                    </div>

                    <div className='users'>
                        {trainer.users && trainer.users.map((user, userIndex) => (
                            <div key={userIndex} className={`user ${user.today === 1 ? '' : 'unasist'}`} onClick={() => goStats(user.id, `${user.name} ${user.paternal_surename} ${user.maternal_surename}`)}>

                                <div className='image'>
                                    <img src={baseURL + user.image} alt={user.name} />
                                    <h4>{user.employ_number}</h4>
                                </div>

                                <div className='data'>

                                    <div className='info'>
                                        <h4>{`${user.name} ${user.paternal_surename} ${user.maternal_surename}`}</h4>

                                        <div className='dates'>
                                            <p>{user.admission} | {user.total_days}</p>
                                        </div>
                                    </div>


                                    <div className='pqa'>

                                        <div className='bar'>
                                            <div className='progress-bar'><p className='percent'>P {parseFloat(user.progress.toFixed(2))}%</p><div className='progress' style={{ width: `${user.progress}%` }}></div></div>
                                        </div>

                                        <div className='bar'>
                                            <div className='progress-bar'><p className='percent'>Q {parseFloat(user.quality.toFixed(2))}%</p><div className='progress' style={{ width: `${user.quality}%` }}></div></div>
                                        </div>

                                        <div className='bar'>
                                            <div className='progress-bar'><p className='percent'>A {parseFloat(user.asists.toFixed(2))}%</p><div className='progress' style={{ width: `${user.asists}%` }}></div></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            ))}
        </div>

        <ModalRate user={showUserRate} name={showUserNameRate}/>
        </>
    )
}
