import React, { useEffect, useState } from 'react'
import { fetchGet, fetchPut, getCookie } from '../../utils/functions'
import { TablePaginate } from './TablePaginate';
import { major } from '../../utils/constants';

export const TableListTrainer = () => {
    const userId = getCookie('id');
    const [ dates, setDates ] = useState([]);
    const [ usersLists, setUsersLists ] = useState([]);

    const getListDates = async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.getCurrentListDates + userId});
        setDates(['', ...data.data.data]);
    };

    const getListUsers = async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.getCurrentListUsers + userId});
        setUsersLists(data.data.data)
    };

    const changeAsist = async (id) => {
        const { data, loading, error } = await fetchPut({endpoint: major.updateAsists + id});
        getListUsers();
    };

    useEffect(() => {
        getListDates();
    }, []);

    return (
        <div className='container-table'>
            <TablePaginate
                paginate={false}

                rows={
                    Object.keys(usersLists).map((key) => (
                        <tr key={key}>
                            <td>{key}</td>

                        {usersLists[key].map((item, index) => (
                            <td key={index}>
                                <input
                                    type='checkbox' checked={item.value}
                                    onChange={() => changeAsist(item.id)}
                                />
                            </td>
                        ))}
                      </tr>
                    ))
                }

                columns={dates}
                functionData={getListUsers}
            />
        </div>
    )   
}