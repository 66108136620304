import React from 'react'
import { confirmCreate, fetchDelete, setCookie } from '../../utils/functions';
import { major } from '../../utils/constants';
import { TablePaginate } from './TablePaginate';
import { useNavigate } from 'react-router-dom';

export const TableContents = ({ data, updateTable }) => {
    const navigate = useNavigate();

    function cookieAndGo(name, cookieValie, route){
        setCookie({ name: name, value: cookieValie })
        navigate(route);
    }

    const destroyContent = async (contentId) => {
        const { data, loading, error } = await fetchDelete({endpoint: major.destroyContent + contentId})
        updateTable();
        return data;
    };

    const swalConfirm = (e, contentId) => {
        confirmCreate('Confirmar', '¿Eliminar registro?').then((confirmed) => {if (confirmed){
        destroyContent(contentId)
    }})}

    return (
        <TablePaginate
            rows={data.map((content, index) => (
                <tr key={index}>
                    <td>{content.title}</td>
                    <td>{content.description}</td>
                    <td>{content.sequence}</td>
                    <td><img src={major.baseURL + content.image}/></td>
                    <td><img src={major.baseURL + content.background}/></td>
                    <td>{content.operation} {content.sublevel}</td>

                    <td>
                        <button onClick={(e) => cookieAndGo('contentId', content.id, '/admin/classes')}>Clases</button>
                        <button onClick={(e) =>cookieAndGo('contentId', content.id, '/admin/content/edit')} className='edit'>Editar</button>
                        <button onClick={(e) => swalConfirm(e, content.id)} className='destroy'>Eliminar</button>
                    </td>
                </tr>
            ))}

            columns={['Títlulo', 'Descripción', 'Secuencia', 'Miniatura', 'Fondo', 'Nivel', 'Acciones']}
            functionData={updateTable}
        />
    )
}
