import { useState } from "react";

export const useCredentials = () => {
    const [username, setUsername] = useState('');
    const handleUsername = (value) => setUsername(value);
  
    const [password, setPassword] = useState('');
    const handlePassword = (value) => setPassword(value);

    const [message, setMessage] = useState('');
    const handleMessage = (value) => setMessage(value);
  
    return{
        username,
        handleUsername,
        password,
        handlePassword,
        message,
        handleMessage
    }
}