import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { ViewAdminDefects } from '../components/views/ViewAdminDefects'

export const AdminDefects = () => {
  return (
    <div>
        <NavbarAdmin/>
        <ViewAdminDefects/>
    </div>
  )
}
