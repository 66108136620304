import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { FormCreateNotification } from '../components/forms/FormCreateNotification'

export const AdminSendMessage = () => {
  return (
    <div>
        <NavbarAdmin/>
        <FormCreateNotification/>
    </div>
  )
}
