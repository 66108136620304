import React from 'react'
import { serve } from '../../utils/constants'
import { useNavigate } from 'react-router-dom'

export const NavbarMenuB = () => {
  const navigate  = useNavigate();

  return (
    <nav>
        <div>
            <div className='logos'>
                <img src={serve}/>
                <p>MAESTRO JOYERO ORFEBRE</p>
            </div>

            <div className='buttons'>
                <div onClick={() => navigate('/')}>Usuario</div>
            </div>
        </div>
    </nav>
  )
}
