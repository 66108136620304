import React from 'react'
import { NavbarMenu } from '../components/navbars/NavbarMenu'
import { FormSimpleLogin } from '../components/forms/FormSimpleLogin'
import { PageStats } from '../components/charts/PageStats'


export const Home = () => {
  return (
    <div>
        <NavbarMenu/>
        <PageStats/>
        <FormSimpleLogin/>
    </div>
  )
}
