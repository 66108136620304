import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { ViewAdminUsers } from '../components/views/ViewAdminUsers'

export const Admin = () => {
  
  return (
    <div>
      <NavbarAdmin/>
      <ViewAdminUsers/>
    </div>
  )
}