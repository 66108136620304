import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { FormEditRecognitionPiece } from '../components/forms/FormEditRecognitionPiece'

export const EditRecognitionPiece = () => {
  return (
    <div>
        <NavbarAdmin/>
        <FormEditRecognitionPiece/>
    </div>
  )
}
