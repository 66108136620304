import React from 'react'
import { major, qrcode, stullerDefault } from '../../utils/constants';
import { confirmCreate, downloadQR, fetchDelete, goView, setCookie } from '../../utils/functions';
import { useNavigate  } from 'react-router-dom';
import { TablePaginate } from './TablePaginate';
import QRCode from 'react-qr-code';

export const TableUsersAdmin = ({ data, updateTable }) => {
  const navigate = useNavigate();

  function cookieAndGo(name, cookieValie, route){
    setCookie({ name: name, value: cookieValie })
    navigate(route);
}

  const destroyUser = async (userId) => {
    const { data, loading, error } = await fetchDelete({endpoint: major.destroyUser + userId})
    updateTable();
    return data;
  };

  const swalConfirm = (e, userId) => {
    confirmCreate('Confirmar', '¿Eliminar registro?').then((confirmed) => {if (confirmed){
      destroyUser(userId)
  }})}

  return (
    <TablePaginate
        functionData={updateTable}
        columns={['Foto', 'Nombre', 'Curso', 'Usuario', 'QR', 'Acciones']}

        rows={data.map((user) => (
            <tr key={user.id}>
                <td><img src={user.image && user.image !== '' ? major.baseURL + user.image : stullerDefault}/></td>
                <td>{user.name} {user.paternal_surename} {user.maternal_surename}</td>
                <td>{user.operation} {user.sublevel}</td>
                <td>{user.username}</td>

                <td>
                  <img src={qrcode} onClick={() => downloadQR(user.username)}/>
                </td>


                <td>
                  <button onClick={(e) =>cookieAndGo('userId', user.id, '/admin/notifications')}>Mensaje</button>
                  <button onClick={(e) => cookieAndGo('userId', user.id, '/admin/user')} className='edit'>Editar</button>
                  <button onClick={(e) => swalConfirm(e, user.id)} className='destroy'>Eliminar</button>
                </td>
            </tr>
        ))}
    />
  )
}