import React, { useState } from 'react'
import { FormCreateRecognitionPiece } from '../forms/FormCreateRecognitionPiece'
import { fetchGet } from '../../utils/functions';
import { major } from '../../utils/constants';
import { TableRecognitionPieces } from '../tables/TableRecognitionPieces';

export const ViewRecognitionPieces = () => {
    const [ pieces, setPieces ] = useState([]);

    const getRecognitionPieces = async (page, filter='') => {
        const pag = '?page=' + page;
        const { data, loading, error } = await fetchGet({
            endpoint: major.getRecognitionPiece  + pag,
            params:{"filter": filter}
        });

        setPieces(data.data.data.data);
        return data;
    };

    return (
        <div className='flex-container'>
            <FormCreateRecognitionPiece updateTable={getRecognitionPieces}/>
            <TableRecognitionPieces data={pieces} updateTable={getRecognitionPieces}/>
        </div>
    )
}
