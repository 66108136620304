import React from 'react'

export const Conffeti = () => {
    const confettiElements = [];
    for (let i = 1; i <= 700; i++) {
        confettiElements.push(<div key={i} className={`confetti-${i}`}></div>);
    }

    return (
        <div id='conffeti'>
            {confettiElements}
        </div>
    );
}
