import React, { useState } from 'react'
import { TableContents } from '../tables/TableContents';
import { fetchGet } from '../../utils/functions';
import { major } from '../../utils/constants';
import { FormCreateContent } from '../forms/FormCreateContent';

export const ViewAdminContents = () => {
    const [ contents, setContents ] = useState([])

    const getContents = async (page, filter='') => {
        const pag = '?page=' + page;
        const { data, loading, error } = await fetchGet({
            endpoint: major.contents  + pag,
            params:{"filter": filter}
        });
        setContents(data.data.data.data);
        return data;
    };

    return (
        <div className='flex-container'>
            <FormCreateContent updateTable={getContents}/>
            <TableContents data={contents} updateTable={getContents}/>
        </div>
    )
}
