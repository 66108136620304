import React from 'react'
import { TablePaginate } from './TablePaginate'

export const TableScoops = ({ data, updateTable }) => {

  return (
    <TablePaginate
        rows={data.map((scoop, index) => (
            <tr key={index}>
                <td>{scoop.link}</td>
            </tr>
        ))}

        columns={['Link']}
        functionData={updateTable}
    />
  )
}
