import jsPDF from 'jspdf'
import 'jspdf-autotable';
import { useEffect } from 'react'
import { fetchGet, getCookie } from '../../utils/functions';
import { admin1001, major, serveblackout } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

export const PdfOrder = () => {
    const order = getCookie('order');
    const user = getCookie('id');
    const navigate = useNavigate();

    const getOrderList = async (e) => {
        const { data } = await fetchGet({
            endpoint: major.getFacture + order,
            params:{
                admin: user
            }
        });
        getUser(data.data.data);
        //console.log(data.data.data)
    };

    const getUser = async (response) => {
        const { data } = await fetchGet({endpoint: major.foilData + user});
        let admin = data.data.data.name + ' ' + data.data.data.paternal_surename + ' ' + data.data.data.maternal_surename;
        generatePDF(response, admin);
        navigate('/admin/order');
    };

    useEffect(() => {
        getOrderList();
    }, []);


    function generatePDF(data, admin){

        const doc = new jsPDF();
        const signature = admin1001;
        const header = serveblackout;

        doc.addImage(header, 'PNG', 10, 10, 40, 15);


        doc.setFontSize(8);
        doc.text('Folio: ' + order, 15, 30);
        doc.text('Fecha: ' + data.date, 170, 30);
        doc.text('Depto: ' + data.operation, 170, 33);

        doc.setFontSize(16);
        doc.text('Solicitud de materiales de entrenamiento', 55, 50);

    
        const columns = ['#', 'Código', 'Nombre', 'Cantidad', 'Unidad'];
        const body = data.data.map((item, index) => {
            const pack = item.pack;
            const total = item.lot;

            let extension = 'PZ';
            let count = total;
            if(item.pack != null && total % pack == 0){
                count = total / pack;
                extension = 'PKT'                
            }

            return [index + 1, item.number, item.name, count, extension];


        });
        
        doc.autoTable({
            startY: 60,
            head: [columns],
            body: body,
            theme: 'grid',
            styles: {
                halign: 'center'
            },
        })

        doc.setFontSize(12);
        doc.text(data.trainer, 32, 253);
        doc.line(30, 255, 70, 255);
        doc.text('Solicitante', 40, 260);

        doc.addImage(signature, 'JPEG', 155, 235, 40, 30);
        doc.line(155, 255, 195, 255);
        doc.text(admin, 165, 260);
    
        window.open(doc.output('bloburl')); 
    }
}
