import React from 'react'
import { Pie } from 'react-chartjs-2'
import { ArcElement, Chart as ChartJs, Legend, Tooltip } from 'chart.js'

export const CakeChart = ({data=[], labels=[]}) => {

    ChartJs.register(ArcElement, Legend, Tooltip);

    const colors = [
        `rgba(255, 159, 64, 0.5)`,  // Naranja
        `rgba(233, 30, 99, 0.5)`,   // Rosa fuerte
        `rgba(0, 188, 212, 0.5)`,   // Cian
        `rgba(205, 220, 57, 0.5)`,  // Lima
        `rgba(96, 125, 139, 0.5)`,  // Azul grisáceo
        `rgba(255, 87, 34, 0.5)`,   // Rojo naranja
        `rgba(63, 81, 181, 0.5)`,   // Azul índigo
        `rgba(158, 158, 158, 0.5)`, // Gris
        `rgba(233, 30, 99, 0.5)`,   // Rosa fuerte
        `rgba(156, 39, 176, 0.5)`,  // Púrpura
        
    ]

    const getColors = () => {
        for (let i = colors.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [colors[i], colors[j]] = [colors[j], colors[i]];
        }

        return colors;
    }

    const datasets = {
        labels: labels,
        datasets: [{
            data: data,
            backgroundColor: getColors(),
            borderColor: '#2f3238',
            borderWidth: 5,
        }],
    }

    const config = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: { position: 'top' }
        },
    }

    return (
        <div className='chart chart-pie'>
            <Pie data={datasets} options={config} />
        </div>
    )
}
