import React, { useState } from 'react'
import { fetchGet } from '../../utils/functions';
import { major } from '../../utils/constants';
import { FormCreateItem } from '../forms/FormCreateItem';
import { TableItems } from '../tables/TableItems';

export const ViewAdminItems = () => {
    const [ items, setItems ] = useState([]); 

    const getItems = async (page, filter='') => {
        const pag = '?page=' + page;
        const { data, loading, error } = await fetchGet({
            endpoint: major.getItems  + pag,
            params:{"filter": filter}
        });
        setItems(data.data.data.data);
        return data;
    };

    return (
        <div className='flex-container'>
            <FormCreateItem updateTable={getItems}/>
            <TableItems data={items} updateTable={getItems}/>
        </div>
    )
}
