import React, { useEffect, useState } from 'react'
import { FormCreate } from './FormCreate'
import { fetchGet, fetchPost, getCookie } from '../../utils/functions';
import { bookman, bulletinTypewriter, colona, edwardianScript, lucida, major, monoline, oldEnglish, regularCorinthia, scriptMTBold, stullerDefault, stullerSend } from '../../utils/constants';
import { baseURL, operations } from '../../utils/constants/apis/major';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export const FormRecognition = () => {
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [ pieces, setPieces ] = useState([]);

    const [ fullName, setFullName ] = useState('');
    const [ employNumber, setEmployNumber ] = useState('');
    const [idCourse, setIdCourse] = useState('');
    const [ idPiece, setIdPiece ] = useState('');
    const [ imagePiece, setImagePiece ] = useState('');
    const [laser, setLaser] = useState('');
    const [laserText, setlaserText] = useState('');
    const [laserFont, setLaserFont] = useState('');
    const [ringSize, setRingSize] = useState('');

    const [currentStep, setCurrentStep] = useState(0);

    const id = getCookie('id');

    const getUser = async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.AdminUser + id});
        let name = data.data.data.name + ' ' + data.data.data.paternal_surename + ' ' + data.data.data.maternal_surename;
        setFullName(name);
        getSublevel(data.data.data.id_course);
        getPieces(data.data.data.id_course);
    };

    const getSublevel = async (course) => {
        const { data, loading, error } = await fetchGet({endpoint: major.getSublevel + course});
        setIdCourse(data.data.data.id)
    };

    const lastCourses = async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.getLastCourses});
        setCourses(data.data.data);
    };

    const getPieces = async (sublevel) => {
        const { data, loading, error } = await fetchGet({endpoint: major.getRecognitionPiecesByOperation + sublevel});
        setPieces(data.data.data);
    };

    function choosePiece(id, image){
        setIdPiece(id);
        setImagePiece(baseURL + image);
        nextStep();
    }

    function changeCourse(value){
        setIdCourse(value);
        getPieces(value);
    }
    function changeLaserFont(value){
        setLaserFont(value);
        nextStep();
    }

    function getLaser(option){
        setLaser(option)

        if(option == 0){
            nextStep(3);
        }

        if(option == 1){
            nextStep();
        }
    }

    const sendRecognition = async (e) => {

        const { data, loading, error } =  await fetchPost({
            notification: false,
            endpoint: major.createRecognition,
            params:{
                'name': fullName,
                'id_piece': idPiece,
                'text': laserText,
                'font': laserFont,
                'size': ringSize,
                'id_user': id,
            },
        });


        Swal.fire({
            imageUrl: stullerSend,
            title: 'Envío exitoso',
            text: 'Se ha enviado tu pieza, ahora estamos trabajando en ella :)',
            timer: 5000,
            toast: true,
            didClose: () => {
                window.history.back();
            },
            showConfirmButton: true,
            confirmButtonText: 'OK'
        }).then((result) => {
            if (result.isConfirmed) {
                window.history.back();
            }
        });
    }

    useEffect(() => {
        getUser();
        lastCourses();
    }, []);

    const nextStep = (step=1) => {
        if (currentStep < 5) { // Asegurarse de no sobrepasar el número de pasos
            setCurrentStep(currentStep + step);
        }
    };

    const previousStep = (step=1) => {
        if (currentStep > 0) { // Asegurarse de no ir por debajo del primer paso
            setCurrentStep(currentStep - step);
        }
    };


    return (
        <form id="msform">
            <ul id="progressbar">
                <li className={currentStep === 0 ? "active" : ""}></li>
                <li className={currentStep === 1 ? "active" : ""}></li>
                <li className={currentStep === 2 ? "active" : ""}></li>
                <li className={currentStep === 3 ? "active" : ""}></li>
                <li className={currentStep === 4 ? "active" : ""}></li>
                <li className={currentStep === 5 ? "active" : ""}></li>
            </ul>

            {currentStep === 0 && (
                <fieldset>
                    <h2 className="fs-title">Datos personales</h2>
                    <h3 className="fs-subtitle">Complete todos los datos</h3>

                    <input type="text" placeholder="Nombre completo" value={fullName} onChange={(e) => (setFullName(e.target.value))}/>
                    <input type="text" placeholder="Número de empleado" value={employNumber} onChange={(e) => (setEmployNumber(e.target.value))}/>

                    <select value={idCourse} onChange={(e) => changeCourse(e.target.value)}>
                        <option value={0}>Curso</option>
                        {courses.map((course) => (<option key={course.id} value={course.id_sublevel}>{course.operation} {course.sublevel} {course.start} - {course.end}</option>))}
                    </select>

                    <input type="button" className="next action-button" value="Next" onClick={(e) => (nextStep(1))} />
                </fieldset>
            )}

            {currentStep === 1 && (
                <fieldset>
                    <h2 className="fs-title">Pieza</h2>
                    <h3 className="fs-subtitle">Complete todos los campos</h3>

                    <div className='grid-images-6'>
                        {pieces.map((piece) => (
                            <div key={piece.id}>
                                <h2>{piece.name}</h2>
                                <img src={baseURL + piece.image} alt={piece.name} onClick={(e) => (choosePiece(piece.id, piece.image))}/>
                            </div>
                        ))}
                    </div>

                    <input type="button" className="previous action-button" value="Previous" onClick={(e) => (previousStep(1))} />
                    <input type="button" className="next action-button" value="Next" onClick={(e) => (nextStep(1))} />
                </fieldset>
            )}

            {currentStep === 2 && (
                <fieldset>
                    <h2 className="fs-title">Grabado láser</h2>
                    <h3 className="fs-subtitle">Complete todos los campos</h3>

                    <img src={imagePiece} className='mini'/>
                    <h1>¿Lleva grabado láser?</h1>

                        <div>
                            <input type="button" className="previous action-button" value="No" onClick={(e) => (getLaser(0))} />
                            <input type="button" className="previous action-button" value="Sí" onClick={(e) => (getLaser(1))} />
                        </div>


                        <input type="button" className="previous action-button" value="Previous" onClick={(e) => (previousStep(1))} />
                        <input type="button" className="next action-button" value="Next" onClick={(e) => (nextStep(1))} />
                </fieldset>
            )}

            {currentStep === 3 && (
                <fieldset>
                    <h2 className="fs-title">Grabado láser</h2>
                    <h3 className="fs-subtitle">Complete todos los campos</h3>

                    <input type="text" placeholder="Frase del grabado láser" value={laserText} onChange={(e) => (setlaserText(e.target.value))}/>

                    <input type="button" className="previous action-button" value="Previous" onClick={(e) => (previousStep(1))} />
                    <input type="button" className="next action-button" value="Next" onClick={(e) => (nextStep(1))} />
                </fieldset>
            )}

            {currentStep === 4 && (
                <fieldset>
                    <h2 className="fs-title">Grabado láser</h2>
                    <h3 className="fs-subtitle">Complete todos los campos</h3>

                    <div className='grid-images-2'>
                        <div>
                            <img src={bookman} onClick={(e) => (changeLaserFont('Bookman'))}/>
                        </div>

                        <div>
                            <img src={colona} onClick={(e) => (changeLaserFont('Colona'))}/>
                        </div>

                        <div>
                            <img src={lucida} onClick={(e) => (changeLaserFont('Lucida'))}/>
                        </div>

                        <div>
                            <img src={oldEnglish} onClick={(e) => (changeLaserFont('Old English'))}/>
                        </div>

                        <div>
                            <img src={scriptMTBold} onClick={(e) => (changeLaserFont('Script MT Bold'))}/>
                        </div>

                        <div>
                            <img src={bulletinTypewriter} onClick={(e) => (changeLaserFont('Bulletin Typewriter'))}/>
                        </div>

                        <div>
                            <img src={edwardianScript} onClick={(e) => (changeLaserFont('Edwardian Script'))}/>
                        </div>

                        <div>
                            <img src={monoline} onClick={(e) => (changeLaserFont('Monoline'))}/>
                        </div>

                        <div>
                            <img src={regularCorinthia} onClick={(e) => (changeLaserFont('Regular Corinthia'))}/>
                        </div>
                    </div>

                    <input type="button" className="previous action-button" value="Previous" onClick={(e) => (previousStep(1))} />
                    <input type="button" className="next action-button" value="Next" onClick={(e) => (nextStep(1))} />
                </fieldset>
            )}

            {currentStep === 5 && (
                <fieldset>
                    <h2 className="fs-title">Tamaño del anillo</h2>
                    <h3 className="fs-subtitle">Complete todos los campos</h3>

                    <select onChange={(e) => setRingSize(e.target.value)}>
                        <option value='0'>Tamaño del anillo</option>
                        <option value='4'>4</option>
                        <option value='4 1/4'>4 1/4</option>
                        <option value='4 1/2'>4 1/2</option>
                        <option value='4 3/4'>4 3/4</option>
                        <option value='5'>5</option>
                        <option value='5 1/4'>5 1/4</option>
                        <option value='5 1/2'>5 1/2</option>
                        <option value='5 3/4'>5 3/4</option>
                        <option value='6'>6</option>
                        <option value='6 1/4'>6 1/4</option>
                        <option value='6 1/2'>6 1/2</option>
                        <option value='6 3/4'>6 3/4</option>
                        <option value='7'>7</option>
                        <option value='7 1/4'>7 1/4</option>
                        <option value='7 1/2'>7 1/2</option>
                        <option value='7 3/4'>7 3/4</option>
                        <option value='8'>8</option>
                        <option value='8 1/4'>8 1/4</option>
                        <option value='8 1/2'>8 1/2</option>
                        <option value='8 3/4'>8 3/4</option>
                        <option value='9'>9</option>
                        <option value='9 1/4'>9 1/4</option>
                        <option value='9 1/2'>9 1/2</option>
                        <option value='9 3/4'>9 3/4</option>
                        <option value='10'>10</option>
                        <option value='10 1/4'>10 1/4</option>
                        <option value='10 1/2'>10 1/2</option>
                        <option value='10 3/4'>10 3/4</option>
                        <option value='11'>11</option>
                        <option value='11 1/4'>11 1/4</option>
                        <option value='11 1/2'>11 1/2</option>
                        <option value='11 3/4'>11 3/4</option>
                        <option value='12'>12</option>
                    </select>

                    <input type="button" className="previous action-button" value="Previous" onClick={(e) => (previousStep(1))} />
                    <input type="button" className="previous action-button" value="Enviar" onClick={(e) => (sendRecognition())} />
                </fieldset>
            )}
        </form>
    )
}