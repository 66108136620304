export const downloadQR = (value) => {
    // Crear un canvas y dibujar el QR en él
    const canvas = document.createElement('canvas');
    const qrCodeURL = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(value)}`;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = () => {
      ctx.drawImage(image, 0, 0);
      // Crear un enlace para descargar la imagen
      const a = document.createElement('a');
      a.href = canvas.toDataURL('image/png');
      a.download = `${value}-qr.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    image.src = qrCodeURL;
  };