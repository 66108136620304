import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { FormEditUser } from '../components/forms/FormEditUser'

export const EditUser = () => {
  return (
    <div>
        <NavbarAdmin/>
        <FormEditUser/>
    </div>
  )
}
