import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { ViewAdminOrderList } from '../components/views/ViewAdminOrderList'
import { NavbarTrainer } from '../components/navbars/NavbarTrainer'
import { getCookie } from '../utils/functions'

export const AdminOrderList = () => {
  const id = getCookie('id');

  return (
    <div>
        { id != 5 &&
          <NavbarAdmin/>
        }

        { id == 5 &&
          <NavbarTrainer/>
        }
        
        <ViewAdminOrderList/>
    </div>
  )
}
