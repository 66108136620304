import React, { useEffect, useState } from 'react'
import { fetchGet, getCookie } from '../../utils/functions';
import { major } from '../../utils/constants';

export const GridPogress = () => {
    const [ contents, setContents ] = useState([]);
    const [percentages, setPercentages] = useState({});

    const userId = getCookie('id');
  
    const getProgress = async () => {
      const { data, loading, error } = await fetchGet({endpoint: major.getProgrressByUser + userId})
      setContents(data.data.data);

      return data;
    };

    const timeout = setTimeout(() => {
        const newPercentages = {};
        Object.entries(contents).forEach(([key, value]) => { newPercentages[key] = value.percent; });
        setPercentages(newPercentages);
    }, 100);
  
    useEffect(() => { getProgress(); }, []);

    useEffect(() => {
        const initialPercentages = {};
        Object.keys(contents).forEach(key => { initialPercentages[key] = 0; });
        setPercentages(initialPercentages);
        return () => clearTimeout(timeout);
    }, [contents]);


    return (
        <div className='grid grid-3'>
            {Object.entries(contents).map(([key, value]) => (
                <div key={key} className='progress-content'>
                    <div className='percent-content'>
                        <div className='percent-content-image'><img src={major.baseURL + value.image} /></div>
                        <div className='percent-progress' style={{ height: `${percentages[key]}%` }}></div>
                        <p>{parseFloat(parseFloat(value.percent).toFixed(2))}%</p>
                    </div>
                    <h2>{key}</h2>
                </div>
            ))}
        </div>
    );
}
