import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/css/styles.css'
import { HashRouter, Routes, Route } from 'react-router-dom';
import { Home } from './views/Home';
import { Login } from './views/Login';
import { Admin } from './views/Admin';
import { Courses } from './views/Courses';
import { EditUser } from './views/EditUser';
import { AdminContents } from './views/AdminContents';
import { AdminClasses } from './views/AdminClasses';
import { Menu } from './views/Menu';
import { Classes } from './views/Classes';
import { ClassWatch } from './views/ClassWatch';
import { Trainer } from './views/Trainer';
import { PiecesUser } from './views/PiecesUser';
import { CurrentListTrainer } from './views/CurrentListTrainer';
import { Progress } from './views/Progress';
import { Rate } from './views/Rate';
import { AdminNews } from './views/AdminNews';
import { EditContent } from './views/EditContent';
import { EditClass } from './views/EditClass';
import { AdminDefects } from './views/AdminDefects';
import { EditDefect } from './views/EditDefect';
import { AdminSendMessage } from './views/AdminSendMessage';
import { TrainerSendMessage } from './views/TrainerSendMessage';
import { AdminItems } from './views/AdminItems';
import { TrainerItems } from './views/TrainerItems';
import { AdminOrders } from './views/AdminOrders';
import { TrainerOrders } from './views/TrainerOrders';
import { TrainerOrderList } from './views/TrainerOrderList';
import { AdminOrderList } from './views/AdminOrderList';
import { PdfOrder } from './components/pdfs/PdfOrder';
import { Major } from './views/Major';
import { MajorInfo } from './views/MajorInfo';
import { EditItem } from './views/EditItem';
import { RecognitionPieces } from './views/RecognitionPieces';
import { FormRecognition } from './components/forms/FormRecognition';
import { EditRecognitionPiece } from './views/EditRecognitionPiece';
import { UserFeedBack } from './views/UserFeedBack';
import { AdminDistribution } from './views/AdminDistribution';
import { TrainerDistribution } from './views/TrainerDistribution';
import { TrainerRecognitions } from './views/TrainerRecognitions';
import { AdminRecognitions } from './views/AdminRecognitions';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <Routes>
      <Route path='/' element={ <Home/> } />
      <Route path='/login' element={ <Login/> } />
      <Route path='/major' element={ <Major/> } />

      <Route path='/admin' element={ <AdminDistribution/> } />
      <Route path='/admin/courses' element={ <Courses/> } />
      <Route path='/admin/user' element={ <EditUser/> } />
      <Route path='/admin/contents' element={ <AdminContents/> } />
      <Route path='/admin/classes' element={ <AdminClasses/> } />
      <Route path='/admin/news' element={ <AdminNews/> } />
      <Route path='/admin/content/edit' element={ <EditContent/> } />
      <Route path='/admin/class/edit' element={ <EditClass/> } />
      <Route path='/admin/defects' element={ <AdminDefects/> } />
      <Route path='/admin/defects/edit' element={ <EditDefect/> } />
      <Route path='/admin/notifications' element={ <AdminSendMessage/> } />
      <Route path='/admin/items' element={ <AdminItems/> } />
      <Route path='/admin/orders' element={ <AdminOrders/> } />
      <Route path='/admin/order' element={ <AdminOrderList/> } />
      <Route path='/admin/order/pdf' element={ <PdfOrder/> } />
      <Route path='/admin/item' element={ <EditItem/> } />
      <Route path='/admin/rec_pzs' element={ <RecognitionPieces/> } />
      <Route path='/admin/rec_pz/edit' element={ <EditRecognitionPiece/> } />
      <Route path='/admin/users' element={ <Admin/> } />
      <Route path='/admin/recognitions' element={ <AdminRecognitions/> } />

      <Route path='/major/admin' element={ <MajorInfo/> } />

      <Route path='/trainer' element={ <TrainerDistribution/> } />
      <Route path='/trainer/user' element={ <PiecesUser/> } />
      <Route path='/trainer/list' element={ <CurrentListTrainer/> } />
      <Route path='/trainer/notifications' element={ <TrainerSendMessage/> } />
      <Route path='/trainer/items' element={ <TrainerItems/> } />
      <Route path='/trainer/orders' element={ <TrainerOrders/> } />
      <Route path='/trainer/orders/edit' element={ <TrainerOrderList/> } />
      <Route path='/trainer/users' element={ <Trainer/> } />
      <Route path='/trainer/recognitions' element={ <TrainerRecognitions/> } />

      <Route path='/menu' element={ <Menu/> } />
      <Route path='/classes' element={ <Classes/> } />
      <Route path='/classes/watch' element={ <ClassWatch/> } />
      <Route path='/progress' element={ <Progress/> } />
      <Route path='/rate' element={ <Rate/> } />
      <Route path='/feedback' element={ <UserFeedBack/> } />
      <Route path='/recognition' element={ <FormRecognition/> } />

    </Routes>
  </HashRouter>
);