import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchGet, fetchPost, getCookie, goView } from '../../utils/functions';
import { major, stullerDefault } from '../../utils/constants';
import { baseURL } from '../../utils/constants/apis/major';

export const GridClasses = () => {
    const [classes, setClasses] = useState([]);
    const [background, setBackground] = useState(null);
    const location = useLocation();
    const contentId = location.state?.variable || null;
    const userId = getCookie('id');
    const navigate = useNavigate();

    const unlockClass = async (id_class, id_user) =>{ 
        const { data, loading, error } = await fetchPost({
            endpoint: major.userUnlockClass,
            params:{
                "id_user": id_user,
                "id_class": id_class
            },
            notification: false
        });

        if(data.data.data == 1){
            window.location.reload();
        }
    };

    const getClasses = async (e) =>{ 
        const { data, loading, error } = await fetchGet({ endpoint: major.userClasses + contentId + '/' + userId});
        setClasses(data.data.data);

        if(data.data.data[0]){
            unlockClass(data.data.data[0].id, userId)
        }
    };
    
    const getBackground = async (e) =>{ 
        const { data, loading, error } = await fetchGet({ endpoint: major.backgroundContent + contentId});

        if (data && data.data && data.data.data) {
            setBackground(baseURL + data.data.data.background);
        }
    };
    
    useEffect(() => { 
        getClasses();
        getBackground();
    }, []);


    return (
        <div className='grid'>
            {classes.map((classs, index) => (
                <div key={index} style={{ opacity: classs.value === 1 ? 1 : 0.5 }}>
                    <h2>{classs.title}</h2>
                    <img 
                        src={classs.image ? baseURL + classs.image : stullerDefault}
                        onClick={classs.value === 1 ? (e) => goView('/classes/watch', classs.id, navigate) : undefined}
                    />
                </div>
            ))}
                    <img className='grid-bg' src={background}/>

        </div>
    )
}
