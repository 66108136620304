import React, { useEffect, useState } from 'react'
import { FormCreate } from './FormCreate'
import { major, stullerDefault } from '../../utils/constants'
import { clicked, fetchGet } from '../../utils/functions'
import { imagePreview } from '../../utils/functions/imagePreview'

export const FormCreateRecognitionPiece = ({ updateTable }) => {
    const [ name, setName ] = useState('');
    const [ image, setImage ] = useState('');
    const [ preview, setPreview ] = useState('');
    const [ sublevel, setSublevel ] = useState('');
    const [ link, setLink ] = useState('');

    const [ operations, setOperations ] = useState([]);
    const [ levels, setLevels ] = useState([]);
    const [ sublevels, setSublevels ] = useState([]);


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(file);  
        const prev = imagePreview(event);
        setPreview(prev);   
    };

    const getOperations = async () => {
        const { data } = await fetchGet({endpoint: major.operations});
        setOperations(data.data.data);
    };

    const getLevels = async (opt) => {
      const { data } = await fetchGet({endpoint: major.levels + opt});
      setLevels(data.data.data);
    };

    const getSublevels = async (lvl) => {
      const { data } = await fetchGet({endpoint: major.sublevels + lvl});
      setSublevels(data.data.data);
  };

    useEffect(() => {
        getOperations();
    }, []);

    return (
      <FormCreate
          form={<>
                <img src={preview || stullerDefault} onClick={() => clicked('create-recognition-piece-image')} />
                <input type="file" id='create-recognition-piece-image' onChange={handleImageChange} style={{ display: 'none' }} />

                <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder='Nombre'/>

                <select onChange={(e) => getLevels(e.target.value)}>
                    <option value={0}>Selecciona una operación</option>
                    {operations.map((operation) => (<option key={operation.id} value={operation.id}>{operation.name}</option>))}
                </select>

                <select onChange={(e) => getSublevels(e.target.value)}>
                    <option value={0}>Selecciona un nivel</option>
                    {levels.map((level) => (<option key={level.id} value={level.id}>{level.name}</option>))}
                </select>

                <select onChange={(e) => setSublevel(e.target.value)}>
                    <option value={0}>Selecciona un subnivel</option>
                    {sublevels.map((sublevel) => (<option key={sublevel.id} value={sublevel.id}>{sublevel.name}</option>))}
                </select>


                <input value={link} onChange={(e) => setLink(e.target.value)} type="text" placeholder='Link de vista previa'/>
          </>}

        params={{
            "name": name,
            "id_sublevel": sublevel,
            "image": image,
            "preview": link
          }}
      
        headers={{"Content-Type" : "multipart/form-data"}}
        endpoint={major.createRecognitionPiece}
        conditions={name === '' || sublevel === '' || image === ''}
        updateTable={updateTable}
      
        resetForm={[setImage, setPreview, setName]}
      />
    )
}
