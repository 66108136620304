import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { ViewAdminItems } from '../components/views/ViewAdminItems'

export const AdminItems = () => {
  return (
    <div>
        <NavbarAdmin/>
        <ViewAdminItems/>
    </div>
  )
}
