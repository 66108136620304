import React from 'react'
import { NavbarUser } from '../components/navbars/NavbarUser'
import { GridRate } from '../components/grids/GridRate'

export const Rate = () => {

  return (
    <div>
        <NavbarUser/>
        <GridRate/>
    </div>
  )
}
