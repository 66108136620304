import React, { useEffect, useState } from 'react'
import { fetchGet, getCookie, goView, modal, setCookie } from '../../utils/functions'
import { major, stullerDefault } from '../../utils/constants';
import { baseURL } from '../../utils/constants/apis/major';
import { useNavigate } from 'react-router-dom';
import { ModalFeedback } from '../modals/ModalFeedback';

export const GridPiecesWithDefectss = () => {
    const navigate = useNavigate();
    const userID = getCookie('id');
    const [ classes, setClasses ] = useState([]);
    const [ classId, setClassId ] = useState(null);

    const getClasses = async (e) =>{ 
        const { data, loading, error } = await fetchGet({ endpoint: major.classesWhithDefects + userID});
        setClasses(data.data.data);
    };

    function showModal(classs){
        setClassId(classs)
        modal('modal-feedback')
    }

    useEffect(() => { getClasses(); }, []);


    return (
        <>
            <div className='grid'>
                {classes.map((classs, index) => {
                    const handleClick = classs.pieces > 0 ? (e) => showModal(classs.id) : undefined;
                    const imgStyle = classs.pieces > 0 ? {} : { opacity: 0.5 };
                
                    return (
                        <div key={index}>
                            <h2>{classs.name}</h2>
                            <img style={imgStyle}
                                src={classs.image ? baseURL + classs.image : stullerDefault}
                                onClick={handleClick}
                            />

                            <span>{classs.value}%</span>

                            {classs.pieces > 0 ? <div className='counter'>{classs.pieces}</div> : ''}
                        </div>
                    );
                })}
            </div>

            <ModalFeedback classId={classId}/>
        </>
    )
}