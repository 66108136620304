import React, { useEffect, useState } from 'react'
import { awards, cart, contents, courses, defects, distribution, major, news, piecesList, recognitions, serve, stullerDefault, users } from '../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { fetchGet, getCookie } from '../../utils/functions';
import { UserOptions } from './UserOptions';

export const NavbarAdmin = () => {
    const [ name, setName ] = useState('');
    const [ image, setImage ] = useState('');
    const [ totalNewOrders, setTotalNewOrders ] = useState('');
    const navigate  = useNavigate();
    const id = getCookie('id');

    const validate = async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.validateUser + id});
        if(data.data.data.role != 300){navigate('/')}
        setName(data.data.data.name);
        setImage(data.data.data.image);
    };

    //obtener los reconocimientos sin
    const newOrders = async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.newOrders});
        setTotalNewOrders(data.data.data)
    };

    useEffect(() => {
        newOrders();
        if(typeof(id) == 'undefined'){
            navigate('/')
        }
        if(typeof(id) == 'string'){
            validate();
        }
    }, []);

    return (
      <nav>
          <div>
              <div className='logos'>
                  <img src={serve}/>
                  <p>MAESTRO JOYERO ORFEBRE</p>
              </div>
  
              <div className='buttons'>
                    <div onClick={() => navigate('/admin/')} className='info views'><img src={distribution} className='invert-color'/><p>Distribución</p></div>
                    <div onClick={() => navigate('/admin/recognitions')} className='info views'><img src={awards} className='invert-color'/><p>Reconocimientos</p></div>
                    <div onClick={() => navigate('/admin/rec_pzs')} className='info views'><img src={recognitions} className='invert-color'/><p>Awards</p></div>

                    <div onClick={() => navigate('/admin/orders')} className='info views'><img src={cart} className='invert-color'/>
                        <p>Órdenes</p>
                        { totalNewOrders > 0 && ( <div className='ballon'>{totalNewOrders}</div> )}
                    </div>
                    <div onClick={() => navigate('/admin/items')} className='info views'><img src={piecesList} className='invert-color'/><p>Materiales</p></div>
                    <div onClick={() => navigate('/admin/news')} className='info views'><img src={news} className='invert-color'/><p>News</p></div>
                    <div onClick={() => navigate('/admin/defects')} className='info views'><img src={defects} className='invert-color'/><p>Defectos</p></div>
                    <div onClick={() => navigate('/admin/contents')} className='info views'><img src={contents} className='invert-color'/><p>Contenidos</p></div>
                    <div onClick={() => navigate('/admin/courses')} className='info views'><img src={courses} className='invert-color'/><p>Cursos</p></div>
                    <div onClick={() => navigate('/admin/users')} className='info views'><img src={users} className='invert-color'/><p>Usuarios</p></div>
                    <UserOptions image={image} name={name}/>
              </div>
          </div>
      </nav>
    )
}
