import React, { useEffect, useState } from 'react'
import { major, stullerDefault } from '../../utils/constants'
import { imagePreview } from '../../utils/functions/imagePreview';
import { clicked, fetchGet, fetchPut, getCookie } from '../../utils/functions';

export const FormEditContent = () => {
    const [ title, setTitle ] = useState('');
    const [ description, setDescription] = useState('');
    const [ sequence, setSequence] = useState('');
    const [ background, setBackground ] = useState('');
    const [ backgroundPreview, setBackgroundPreview ] = useState('');
    const [ miniature, setMiniature] = useState('');
    const [ miniaturePreview, setMiniaturePreview ] = useState('');
    const contentId = getCookie('contentId');

    const handleBackgroundChange = (event) => {
        const file = event.target.files[0];
        setBackground(file);  
        const prev = imagePreview(event);
        setBackgroundPreview(prev);   
    };

    const handleMiniatureChange = (event) => {
        const file = event.target.files[0];
        setMiniature(file);  
        const prev = imagePreview(event);
        setMiniaturePreview(prev);   
    };

    const FormUpdateContent = async (e) => {
        e.preventDefault();
    
        const { data, loading, error } = 
        await fetchPut({
            endpoint: major.updateContent + contentId,
            params:{
              "title": title,
              "description": description,
              "sequence": sequence,
              "miniature": miniature,
              "background": background
            },
            headers:{"Content-Type" : "multipart/form-data"}
        });
    };

    const getContent = async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.getContent + contentId});
        setTitle(data.data.data.title);
        setDescription(data.data.data.description);
        setSequence(data.data.data.sequence);

        setBackground(data.data.data.background);
        if(data.data.data.background){setBackgroundPreview(major.baseURL + data.data.data.background);}

        setMiniature(data.data.data.image);
        if(data.data.data.image){setMiniaturePreview(major.baseURL + data.data.data.image);}
    };

    useEffect(() => {
        getContent();
      }, []);

    return (
      <div className='form-flex'>    
            <form >
              <h2>EDITAR CONTENIDO</h2>

                <input placeholder='Título' value={title} onChange={(e) => (setTitle(e.target.value))}/>
                <input placeholder='Descripción' value={description} onChange={(e) => (setDescription(e.target.value))}/>
                <input placeholder='Sequencia' value={sequence} onChange={(e) => (setSequence(e.target.value))}/>


                <div className='images-group'>
                    <div className='image-with-title'>
                        <h2>Fondo</h2>
                        <img src={backgroundPreview || stullerDefault}  onClick={() => clicked('background_admin_content_edit')}/>
                        <input id='background_admin_content_edit' type='file' onChange={handleBackgroundChange} style={{ display: 'none' }}/>
                    </div>
        
                    <div className='image-with-title'>
                        <h2>Miniatura</h2>
                        <img src={miniaturePreview || stullerDefault}  onClick={() => clicked('miniature_admin_content_edit')}/>
                        <input id='miniature_admin_content_edit' type='file' onChange={handleMiniatureChange} style={{ display: 'none' }}/>
                    </div>
                </div>

                <button type='submit' onClick={(e) => FormUpdateContent(e)}>Editar</button>
            </form>
        </div>
    )
}
