import React, { useEffect, useState } from 'react'
import { major } from '../../utils/constants';
import { fetchGet } from '../../utils/functions';
import { TablePaginate } from './TablePaginate';

export const TableCourses = ({data, updateTable}) => {
    return (
        <TablePaginate
            rows={data.map((course, index) => (
                <tr key={index}>
                    <td>{course.start}</td>
                    <td>{course.end}</td>
                    <td>{course.operation} {course.sublevel}</td>
                    <td>{course.name} {course.paternal_surename} {course.maternal_surename}</td>
                </tr>
            ))}

            columns={['Fecha de inicio', 'Fecha de fin', 'Nivel', 'Entrenador']}
            functionData={updateTable}
        />
    )
}
