import React, { useState } from 'react'
import { fetchGet } from '../../utils/functions';
import { major } from '../../utils/constants';
import { TableScoops } from '../tables/TableScoops';
import { FormCreateNew } from '../forms/FormCreateNew';

export const ViewAdminNews = () => {
    const [ scoops, setScoops ] = useState([]); 

    const getScoops = async (page, filter='') => {
        const pag = '?page=' + page;
        const { data, loading, error } = await fetchGet({
            endpoint: major.getScoops  + pag,
            params:{"filter": filter}
        });

        setScoops(data.data.data.data);
        return data;
    };

    return (
      <div className='flex-container'>
        <FormCreateNew updateTable={getScoops}/>
        <TableScoops data={scoops} updateTable={getScoops}/>
      </div>
    )
}
