import React, { useEffect } from 'react'
import { getCookie, modal } from '../../utils/functions'
import { GridRate } from '../grids/GridRate'

export const ModalRate = ({user, name}) => {

    return (
      <div id='modal-rate' className='modal'>
          <div className='header'>
            <h2>{name}</h2>
            <div className='close' onClick={() => modal('modal-rate')}>X</div>
          </div>

          <div className='iframe'>
              <GridRate id={user}/>
          </div>
      </div>
    )
}
