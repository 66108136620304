import React from 'react'

export const Carousel = ({ text }) => {
  return (
    <div class="slider">
        <div class="slide-track">
            <div class="slide">
                <p>{text}</p>
            </div>
        </div>
    </div>
  )
}
