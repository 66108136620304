import React from 'react'
import { NavbarTrainer } from '../components/navbars/NavbarTrainer'
import { TableRecognitions } from '../components/tables/TableRecognitions'

export const TrainerRecognitions = () => {

  return (
    <div>
        <NavbarTrainer/>
        <TableRecognitions/>
    </div>
  )
}
