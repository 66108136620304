import React, { useEffect, useState } from 'react'
import { clicked, fetchGet, fetchPut, getCookie } from '../../utils/functions'
import { imagePreview } from '../../utils/functions/imagePreview';
import { major, stullerDefault } from '../../utils/constants'

export const FormEditClass = () => {
    const [ title, setTitle ] = useState('');
    const [ link, setLink] = useState('');
    const [ sequence, setSequence ] = useState('');
    const [ miniature, setMiniature ] = useState('');
    const [ miniaturePreview, setMiniaturePreview ] = useState('');
    const classId = getCookie('classId');

    const [ pieces, setPieces ] = useState([]);
    const [ piece1, setPiece1 ] = useState('');
    const [ piece2, setPiece2 ] = useState('');
    const [ piece3, setPiece3 ] = useState('');

    const [ lot1, setLot1 ] = useState('');
    const [ lot2, setLot2 ] = useState('');
    const [ lot3, setLot3 ] = useState('');

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setMiniature(file);  
        const prev = imagePreview(event);
        setMiniaturePreview(prev);   
    };

    const FormUpdateClass = async (e) => {
        e.preventDefault();
    
        const { data, loading, error } = 
        await fetchPut({
            endpoint: major.updateClass + classId,
            params:{
              "title": title,
              "link": link,
              "sequence": sequence,
              "image": miniature,
              "piece1": piece1,
              "lot1": lot1,
              "piece2": piece2,
              "lot2": lot2,
              "piece3": piece3,
              "lot3": lot3
            },
            headers:{"Content-Type" : "multipart/form-data"}
        });
    };


    const getClass = async (e) => {
        const { data, loading, error } = 
        await fetchGet({
            endpoint: major.getClass + classId,
        });

        setTitle(data.data.data.title);
        setLink(data.data.data.file);
        setSequence(data.data.data.sequence);
        setMiniature(data.data.data.image);
        if(data.data.data.image){setMiniaturePreview(major.baseURL + data.data.data.image);}
    };

    const getPieces = async (e) => {
        const { data, loading, error } = 
        await fetchGet({
            endpoint: major.getRecognitionsByClass + classId,
        });
        setPieces(data.data.data);

        getPiecesInClass();
    };

    const getPiecesInClass = async (e) => {
        const { data, loading, error } = 
        await fetchGet({
            endpoint: major.getPiecesByClass + classId,
        });

        if(data.data.data[0]){
            setPiece1(data.data.data[0].id_recognition);
            setLot1(data.data.data[0].lot)
        }

        if(data.data.data[1]){
            setPiece2(data.data.data[1].id_recognition);
            setLot2(data.data.data[1].lot)
        }

        if(data.data.data[2]){
            setPiece3(data.data.data[2].id_recognition);
            setLot3(data.data.data[2].lot)
        }
    };

    useEffect(() => {
        getClass();
        getPieces();
    }, []);

    return (
      <div>
          <div className='form-flex'>    
              <form >
                <h2>EDITAR CLASE</h2>

                  <img src={miniaturePreview || stullerDefault} onClick={() => clicked('image_admin_class_edit')}/>
                  <input id='image_admin_class_edit' type='file' onChange={handleImageChange} style={{ display: 'none' }}/>

                  <input placeholder='Título' value={title} onChange={(e) => (setTitle(e.target.value))}/>
                  <input placeholder='Link del contenido' value={link} onChange={(e) => (setLink(e.target.value))}/>
                  <input placeholder='Secuencia' value={sequence} onChange={(e) => (setSequence(e.target.value))}/>

                  <label>
                    <select value={piece1} onChange={(e) => setPiece1(e.target.value)}>
                        <option value={null}>Selecciona una pieza</option>
                        {pieces.map((piece) => (<option key={piece.id} value={piece.id}>{piece.name}</option>))}
                    </select>
                    
                    <input placeholder='Piezas' value={lot1} onChange={(e) => (setLot1(e.target.value))}/>
                  </label>

                  <label>
                    <select  value={piece2} onChange={(e) => setPiece2(e.target.value)}>
                        <option value={null}>Selecciona una pieza</option>
                        {pieces.map((piece) => (<option key={piece.id} value={piece.id}>{piece.name}</option>))}
                    </select>
                    
                    <input placeholder='Piezas' value={lot2} onChange={(e) => (setLot2(e.target.value))}/>
                  </label>

                  <label>
                    <select  value={piece3} onChange={(e) => setPiece3(e.target.value)}>
                        <option value={null}>Selecciona una pieza</option>
                        {pieces.map((piece) => (<option key={piece.id} value={piece.id}>{piece.name}</option>))}
                    </select>
                    
                    <input placeholder='Piezas' value={lot3} onChange={(e) => (setLot3(e.target.value))}/>
                  </label>

                  <button type='submit' onClick={(e) => FormUpdateClass(e)}>Editar</button>
              </form>
          </div>
      </div>
    )
}
