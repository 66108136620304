import React, { useEffect, useState } from 'react';
import { major, qrcode, stullerGraphic } from '../../utils/constants';
import { useCredentials } from '../../utils/hooks';
import { fetchGet, getCookie, setCookie } from '../../utils/functions';
import { useNavigate } from 'react-router-dom';

import { Html5QrcodeScanner } from "html5-qrcode";


export const FormSimpleLogin = () => {
  const { username, handleUsername } = useCredentials();
  const { message, handleMessage } = useCredentials();
  const navigate = useNavigate();

  const [scannerActive, setScannerActive] = useState(false);
  let scanner;

  useEffect(() => {
    if (scannerActive) {
      scanner = new Html5QrcodeScanner('reader', {
        qrbox: { width: 260, height: 260 },
        fps: 5,
      });
      scanner.render(success, error);
    }

    return () => {
      if (scanner) {
        scanner.clear();
      }
    };
  }, [scannerActive]);

  const startScanner = () => {
    setScannerActive(true);
  };

  const stopScanner = () => {
    setScannerActive(false);
  };

  function success(result) {
    handleUsername(result);
    login(1, result);
  }

  function error(err) {
    console.warn(err);
  }


    const login = async (e, uname = username) => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }

        const { data, loading, error } = await fetchGet({
            endpoint: major.login,
            params:{
            "username": uname,
            "password": null,
        }});

        if (data.data.code == 200) {
          setCookie({ name: 'id', value: data.data.data.id, expire: 1825 });
          setCookie({ name: 'login', value: true, expire: 1825 });
          data?.data?.data?.role === 100 && navigate('/menu');
          data?.data?.data?.role === 200 && navigate('/trainer');
          data?.data?.data?.role === 300 && navigate('/admin');
        }

        handleMessage(data.data.message)
    };

  return (
    <div className='form-login'>
        <div className='title'>
          <h2>INGRESAR</h2>
          {!scannerActive && ( <> <button onClick={startScanner}><img src={qrcode}/></button> </> )}
          {scannerActive && ( <> <button onClick={stopScanner}><img src={qrcode}/></button> </> )}
        </div>

      <form>
        {!scannerActive && ( <>
          <div className='image'><img src={stullerGraphic} alt="Stuller Graphic" /></div>
          <button onClick={startScanner}>Ingresar</button> 
          </> )}
      </form>

      {scannerActive && ( <>
        <div id='reader'></div>
        <span><p>{message}</p></span>
      </> )}

    </div>
  );
};