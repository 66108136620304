import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin';
import { FormEditDefect } from '../components/forms/FormEditDefect';


export const EditDefect = () => {
  return (
    <div>
        <NavbarAdmin/>
        <FormEditDefect/>
    </div>
  )
}
