import axios from "axios";
import { confirmCreate, toastLoading, toastPost } from "./toasts";

export async function fetchGet({ endpoint, params = {}, notification = true }) {
    let data = null;
    let error = null;
    let loading = true; // Inicia loading como true
    let loadingToast;

    if (notification) {
        loadingToast = toastLoading();
    }

    await axios.get(endpoint, { params: params })
        .then((response) => data = response)
        .catch((e) => error = e)
        .finally(() => loading = false);

    loading === false && loadingToast?.close();
    return { data, loading, error }
}

export async function fetchPost({endpoint, params={}, headers={}, notification=true}) {
    let data = null;
    let loading = true;
    let error = null;

    loading = true;
    const loadingToast = toastLoading();

    await axios.post(endpoint, params, { headers })
        .then((response) => data = response)
        .catch((e) => error = e)
        .finally(() => loading = false);

    loading === false && loadingToast?.close();
    //if(notification == true){toastPost(data.data.message, data.data.code);}
    return { data, loading, error }
}

export async function fetchPut({endpoint, params={}, headers={}}) {
    let data = null;
    let loading = true;
    let error = null;

    loading = true;
    const loadingToast = toastLoading();

    await axios.post(endpoint, params, { headers })
        .then((response) => data = response)
        .catch((e) => error = e)
        .finally(() => loading = false);

    loading === false && loadingToast?.close();
    toastPost(data.data.message, data.data.code);
    return { data, loading, error }
}

export async function fetchDelete({endpoint, params={}, headers={}}) {
    let data = null;
    let loading = true;
    let error = null;

    loading = true;
    const loadingToast = toastLoading();

    await axios.delete(endpoint, params, { headers })
        .then((response) => data = response)
        .catch((e) => error = e)
        .finally(() => loading = false);

    loading === false && loadingToast?.close();
    toastPost(data.data.message, data.data.code);
    return { data, loading, error }
}