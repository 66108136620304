import React, { useState } from 'react'
import { TablePaginate } from './TablePaginate'
import { major } from '../../utils/constants';
import { fetchGet, fetchPut, getCookie } from '../../utils/functions';

export const TableRecognitions = ({showAll = false}) => {
    const [ recognitions, setRecognitions ] = useState([]);
    const userId = getCookie('id');

    const getRecogntions = async (page, filter='') => {
        const pag = '?page=' + page;
        const { data, loading, error } = await fetchGet({
            endpoint: major.getRecognitions  + pag,
            params:{
                "filter": filter,
                "user_id": userId
            }
        });
        setRecognitions(data.data.data.data);
        return data;
    };

    const changeStatus = async (rec, status) => {
        const { data, loading, error } = await fetchPut({
            endpoint: major.changeStatusRecognition + rec + '/' + status
        });
    
        setRecognitions(currentRecognitions =>
            currentRecognitions.map(recognition =>
                recognition.id === rec ? { ...recognition, status: status } : recognition
            )
        );
    }

    return (
        <TablePaginate
        rows={recognitions.map((recognition, index) => ( 
            <tr key={index}>
                <td>{recognition.employ_number}</td>
                <td>{recognition.name} {recognition.paternal_surename} {recognition.maternal_surename}</td>
                <td>{recognition.piece}</td>
                <td>{recognition.size}</td>
                <td>{recognition.text}</td>
                <td>{recognition.font}</td>

                <td>
                    <select value={recognition.status} onChange={(e) => changeStatus(recognition.id, e.target.value)}>
                        <option key='0' value="0">En proceso</option>
                        <option key='1' value="1">Sizing</option>
                        <option key='2' value="3">Grabado</option>
                        <option key='4' value="4">Pulido</option>
                        <option key='5' value="5">Engarce</option>
                        <option key='3' value="6">Listo</option>
                        <option key='3' value="7">Entregado</option>
                    </select>
                </td>
            </tr>
        ))}

        columns={['Número', 'Usuario', 'Pieza', 'Tamaño', 'Texto', 'Fuente', 'Status']}
        functionData={getRecogntions}
    />
    )
}
