import React from 'react'
import { NavbarUser } from '../components/navbars/NavbarUser'
import { GridPogress } from '../components/grids/GridPogress'

export const Progress = () => {
  return (
    <div>
        <NavbarUser/>
        <GridPogress/>
    </div>
  )
}
