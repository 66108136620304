import React, { useEffect, useState } from 'react'
import { fetchGet, fetchPost, getCookie, goView } from '../../utils/functions';
import { major, stullerDefault } from '../../utils/constants';
import { baseURL } from '../../utils/constants/apis/major';
import { useNavigate } from 'react-router-dom';

export const GridContents = () => {
    const [contents, setContents] = useState([]);
    const navigate = useNavigate();
    const id = getCookie('id');

    const unlockContent = async (id_content, id_user) =>{ 
        const { data, loading, error } = await fetchPost({
            endpoint: major.userUnlockContent,
            params:{
                "id_user": id_user,
                "id_content": id_content
            },
            notification: false
        });

        if(data.data.data == 1){
            window.location.reload();
        }
    };

    const getContents = async (e) =>{ 
        const { data, loading, error } = await fetchGet({ endpoint: major.userContents + id});
        setContents(data.data.data);

        if(data.data.data[0]){
            unlockContent(data.data.data[0].id, id)
        }
    };

    useEffect(() => { getContents(); }, []);

    return (
        <div className='grid'>
            {contents.map((content, index) => (
                <div key={index} style={{ opacity: content.value === 1 ? 1 : 0.5 }}>
                    <h2>{content.title}</h2>
                    <img
                        src={content.image ? baseURL + content.image : stullerDefault}
                        onClick={content.value === 1 ? (e) => goView('/classes', content.id, navigate) : undefined}
                    />
                </div>
            ))}
        </div>
    )
}
