import { useState } from "react";

export const useCourse = () => {
    const [operations, setOperations] = useState([]);
    const handleOperations = (value) => setOperations(value);

    const [levels, setLevels] = useState([]);
    const handleLevels = (value) => setLevels(value);

    const [sublevels, setSublevels] = useState([]);
    const handleSublevels = (value) => setSublevels(value);

    const [trainers, setTrainers] = useState([]);
    const handleTrainers = (value) => setTrainers(value);

    const [start, setStart] = useState('');
    const handleStart = (value) => setStart(value);

    const [end, setEnd] = useState('');
    const handleEnd = (value) => setEnd(value);

    const [sublevel, setSublevel] = useState(0);
    const handleSublevel = (value) => setSublevel(value);

    const [trainer, setTrainer] = useState(0);
    const handleTrainer = (value) => setTrainer(value);
  
    return{
        operations,
        handleOperations,
        levels,
        handleLevels,
        sublevels,
        handleSublevels,
        start,
        handleStart,
        end,
        handleEnd,
        trainers,
        handleTrainers,
        sublevel,
        handleSublevel,
        trainer,
        handleTrainer
    }
}