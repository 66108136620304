import React, { useEffect, useState } from 'react'
import { major, stullerDefault } from '../../utils/constants'
import { cleanUpLandbot, closeSesion, fetchGet, getCookie, modal } from '../../utils/functions'
import { useNavigate } from 'react-router-dom'
import { baseURL } from '../../utils/constants/apis/major'
import { ModalNotifications } from '../modals/ModalNotifications'

export const UserOptions = ({ image, name, gallery=null, user=false }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [ courseId, setCourseId ] = useState('');
    const [ notifications, setNotifications ] = useState([]);
    const [ countNotifications, setCountNotifications ] = useState(0);

    const [ progress, setProgress ] = useState('');



    const id = getCookie('id');

    const navigate = useNavigate();

    function userOptions() {
        setShowOptions(!showOptions);
    }

    function close(){
      closeSesion(navigate)
      cleanUpLandbot();
    }

    function goGallery(){
      //console.log('go gallery');
    }
    
    const getUser = async (e) => {
        const { data } = await fetchGet({endpoint: major.TrainerUser + id});
        setCourseId(data.data.data.id_course);
        getNotifications(id, courseId);
    };

    const getProgress = async (e) => {
      const { data } = await fetchGet({endpoint: major.getProgressClassesByUser + id});

      let percent = (data.data.data[0].classes * 100) / data.data.data[0].total_classes;
      setProgress(percent);
  };

    const getNotifications = async (user, course) => {
      const { data } = await fetchGet({
        endpoint: major.getNotificationsByUser,
        params:{
          "id_user": id,
          "to_course": courseId
        },
      });
      setNotifications(data.data.data);

      let count = Object.keys(data.data.data).length;
      setCountNotifications(count);
  };

  
    useEffect(() => {
        getUser();
        getProgress();
    }, []);

    return (
      <>
       <div className='info' onClick={() => userOptions()}>
          <img src={image ? baseURL + image : stullerDefault} />{ countNotifications > 0 && <span className='notifications'>{countNotifications}</span>}

          <div id='options-bar-user-image' className={`options-bar ${showOptions ? 'options-bar-show' : ''}`}>

            <p>Hola {name}</p>

            { (!isNaN(progress) && progress !== '') && user == true && user == true && 
              <div className='content-progress'>
                <div className='progress-bar'><div className='progress' style={{ width: `${(progress)}%` }}></div></div>
                <p>{parseFloat(progress.toFixed(2))}%</p>
              </div> }

            { countNotifications > 0 && <div onClick={() => modal('modal-notifications')}>Notificaciones <span className='notifications'>{countNotifications}</span></div> }

            {gallery && <div onClick={() => goGallery()}>Galería</div>}

            <div onClick={() => close()}>Cerrar sesión</div>

          </div>
        </div>
        
        <ModalNotifications notifications={notifications}/>
      </>
    )
}
