import React, { useState } from 'react'
import { major, stullerDefault } from '../../utils/constants';
import { clicked, getCookie } from '../../utils/functions';
import { imagePreview } from '../../utils/functions/imagePreview';
import { FormCreate } from './FormCreate';

export const FormCreateClass = ({ updateTable }) => {
    const contentId = getCookie('contentId');

    const [ image, setImage ] = useState('');
    const [ preview, setPreview ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ file, setFile ] = useState('');
    const [ position, setPosition ] = useState('');

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(file);  
        const prev = imagePreview(event);
        setPreview(prev);   
    };

    return (
        <FormCreate
            form={<>
                <img src={preview || stullerDefault}  onClick={() => clicked('image_admin_class')}/>
                <input id='image_admin_class' type='file' onChange={handleImageChange} style={{ display: 'none' }}/>

                <label>
                    <input value={title} placeholder='Título' onChange={(e) => setTitle(e.target.value)}/>
                </label>

                <label>
                    <input value={file} placeholder='Link del contenidos' onChange={(e) => setFile(e.target.value)}/>
                </label>

                <label>
                    <input value={position} placeholder='Secuencia' type='Secuencia' onChange={(e) => setPosition(e.target.value)}/>
                </label>
            </>}

            params={{
                "title": title,
                "file": file,
                "sequence": position,
                "image": image,
                "id_content": contentId
            }}

            headers={{"Content-Type" : "multipart/form-data"}}
            endpoint={major.createClass}
            conditions={title === '' || file === '' || position === ''}
            updateTable={updateTable}

            //resetForm={[setImage, setPreview, setTitle, setFile, setPieces, setPosition]}
        />
    )
}
