import React from 'react'
import { modal } from '../../utils/functions'

export const ModalNotifications = ({ notifications }) => {
  return (
<>
      <div id={'modal-notifications'}>
        <div className='header'>
          <h2>Notificaciones</h2>
          <div className='close' onClick={() => modal('modal-notifications')}>X</div>
        </div>
    
        <div className='content'>
          {notifications.map((notification, index) => (
            <div key={index}>
              <div>
                <div>{notification.message}</div>
              </div>
          </div>
          ))}
        </div>
      </div>
   
    </>    
  )
}
