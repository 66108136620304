import React from 'react'
import { NavbarTrainer } from '../components/navbars/NavbarTrainer'
import { ViewTrainerOrderList } from '../components/views/ViewTrainerOrderList'

export const TrainerOrderList = () => {
  return (
    <div>
        <NavbarTrainer/>
        <ViewTrainerOrderList/>
    </div>
  )
}
