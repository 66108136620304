import React from 'react'
import { IframeClassWatch } from '../components/iframes/IframeClassWatch';
import { NavbarUser } from '../components/navbars/NavbarUser';

export const ClassWatch = () => {
    return(
        <div>
            <NavbarUser/>
            <IframeClassWatch/>
        </div>
    )
}
