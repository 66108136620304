import React, { useEffect, useState } from 'react'
import { activities, current_users, major, stullerDefault, time, total_users } from '../../utils/constants';
import { fetchGet } from '../../utils/functions';

export const PageStats = () => {
    const [ users, setUsers ] = useState('');
    const [ totalTime, setTotalTime ] = useState('');
    const [ totalUsers, setTotalUsers ] = useState('');
    const [ totalActivities, setTotalActivities ] = useState('');

    const bubbles = async () => {
        const { data, loading, error } = await fetchGet({ endpoint: major.bubbles });
        setUsers(data.data.data.current_users)
        setTotalTime(data.data.data.hours)
        setTotalUsers(data.data.data.total_users)
        setTotalActivities(data.data.data.classes)
    };

    useEffect(() => {
        bubbles();
      }, []);

    return (
      <div className='bubbles'>
        <div>
            <img src={current_users}/>
            <span>
                <h2>{users}</h2>
                <p>Usuarios en curso</p>
            </span>
        </div>

        <div>
            <img src={time}/>
            <span>
                <h2>+{totalTime}h</h2>
                <p>Horas de formación</p>
            </span>
        </div>

        <div>
            <img src={activities}/>
            <span>
                <h2>+{totalActivities}</h2>
                <p>Clases</p>
            </span>
        </div>

        <div>
            <img src={total_users}/>
            <span>
                <h2>{totalUsers}</h2>
                <p>Graduados</p>
            </span>
        </div>

      </div>
    )
}
