export function changePage(page, maxPage){
    let newPage = page;

    if(page < 1){
        newPage = 1;
    };

    if(page > maxPage){
        newPage = maxPage;
    };

    return newPage;
}