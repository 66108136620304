import React, { useEffect, useState } from 'react';
import { fetchGet } from '../../utils/functions';
import { major } from '../../utils/constants';
import { useCourse } from '../../utils/hooks/useCourse';
import { FormCreate } from './FormCreate';

export const FormCreateCourse = ({updateTable}) => {
    const { operations, handleOperations } = useCourse();
    const { levels, handleLevels } = useCourse();
    const { sublevels, handleSublevels } = useCourse();
    const { trainers, handleTrainers } = useCourse();
    const { start, handleStart } = useCourse();
    const { end, handleEnd } = useCourse();
    const { sublevel, handleSublevel } = useCourse();
    const { trainer, handleTrainer } = useCourse();

    const [opt, setOpt ] = useState('');
    const [lv, setLv ] = useState('');

    const getOperations = async () => {
        const { data } = await fetchGet({endpoint: major.operations});
        handleOperations(data.data.data);
    };

    const getLevels = async (opt) => {
        const { data } = await fetchGet({endpoint: major.levels + opt});
        handleLevels(data.data.data);
        setOpt(opt);
    };

    const getSublevels = async (lvl) => {
        const { data } = await fetchGet({endpoint: major.sublevels + lvl});
        handleSublevels(data.data.data);
        setLv(lvl);
    };

    const getTrainers = async () => {
        const { data } = await fetchGet({endpoint: major.trainers});
        handleTrainers(data.data.data);
    };

    useEffect(() => {
        getOperations();
        getTrainers();
    }, []);

    return (
        <FormCreate
            form={<>
                <label><span>Inicio:</span> <input value={start} onChange={(e) => handleStart(e.target.value)} type="date" /></label>
                <label><span>Fin:</span> <input value={end} onChange={(e) => handleEnd(e.target.value)} type="date" /></label>

                <select onChange={(e) => getLevels(e.target.value)} value={opt}>
                    <option value={0}>Selecciona una operación</option>
                    {operations.map((operation) => (<option key={operation.id} value={operation.id}>{operation.name}</option>))}
                </select>

                <select onChange={(e) => getSublevels(e.target.value)} value={lv}>
                    <option value={0}>Selecciona un nivel</option>
                    {levels.map((level) => (<option key={level.id} value={level.id}>{level.name}</option>))}
                </select>

                <select onChange={(e) => handleSublevel(e.target.value)} value={sublevel}>
                    <option value={0}>Selecciona un subnivel</option>
                    {sublevels.map((sublevel) => (<option key={sublevel.id} value={sublevel.id}>{sublevel.name}</option>))}
                </select>

                <select onChange={(e) => handleTrainer(e.target.value)} value={trainer}>
                    <option value={0}>Selecciona un entrenador</option>
                    {trainers.map((trainer) => (<option key={trainer.id} value={trainer.id}>{trainer.name} {trainer.paternal_surename} {trainer.maternal_surename}</option>))}
                </select>
            </>}

            params={{
                "start": start,
                "end": end,
                "id_sublevel": sublevel,
                "id_trainer": trainer
            }}

            endpoint={major.course}
            conditions={start == '' || end == '' || sublevel == 0 || trainer == 0}
            updateTable={updateTable}

            resetForm={[handleStart, handleEnd, setOpt, setLv, handleSublevel, handleTrainer]}

        />
    );
};
