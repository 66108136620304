import React, { useState } from 'react'
import { fetchGet } from '../../utils/functions';
import { major } from '../../utils/constants';
import { TableCourses } from '../tables/TableCourses';
import { FormCreateCourse } from '../forms/FormCreateCourse';

export const ViewAdminCourses = () => {
    const [ courses, setCourses ] = useState([])  

    const getCourses = async (page, filter='') => {
        const pag = '?page=' + page;
        const { data, loading, error } = await fetchGet({
            endpoint: major.courses  + pag,
            params:{"filter": filter}
        });
        setCourses(data.data.data.data);
        return data;
    };

    return (
        <div className='flex-container'>
            <FormCreateCourse updateTable={getCourses}/>
            <TableCourses data={courses} updateTable={getCourses}/>
        </div>
    )
}
