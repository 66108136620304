import React from 'react'
import { TablePaginate } from './TablePaginate'
import { baseURL } from '../../utils/constants/apis/major'
import { setCookie } from '../../utils/functions'
import { useNavigate } from 'react-router-dom'

export const TableRecognitionPieces = ({ data, updateTable, shoping }) => {
    const navigate = useNavigate();

    function cookieAndGo(name, cookieValue, route){
        setCookie({ name: name, value: cookieValue })
        navigate(route);
    }

    return (
        <TablePaginate
            rows={data.map((piece, index) => ( 
                <tr key={index}>
                    <td><img src={baseURL + piece.image}/></td>
                    <td>{piece.name}</td>
                    <td>{piece.operation} {piece.sublevel}</td>

                    <td>
                        <button className='edit' onClick={(e) =>cookieAndGo('recognition_piece', piece.id, '/admin/rec_pz/edit')}>Editar</button>
                    </td>
                </tr>
            ))}
    
            columns={['Imagen', 'Nombre', 'Operación', 'Acciones']}
            functionData={updateTable}
        />
    )
}