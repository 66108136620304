import React, { useEffect, useState } from 'react'
import { confirmCreate, fetchGet, fetchPut, getCookie } from '../../utils/functions'
import { major } from '../../utils/constants';
import { FormCreateCart } from '../forms/FormCreateCart';
import { TableItemsTrainer } from '../tables/TableItemsTrainer';
import { cartList } from '../../utils/functions/cart';

export const ViewAdminOrderList = () => {
    const [ cart, setCart ] = useState([]);
    const [ items, setItems ] = useState([]);
    const order = getCookie('order');

    const getOrderList = async (e) => {
        const { data } = await fetchGet({endpoint: major.getOrderList + order});
        setCart(data.data.data)
    };

    const getItems = async (page, filter='') => {
        const pag = '?page=' + page;
        const { data, loading, error } = await fetchGet({
            endpoint: major.getItems  + pag,
            params:{"filter": filter}
        });

        setItems(data.data.data.data);
        return data;
    };

    function shoping(id, total, number, image) {
        let crt = cartList(id, total, number, image, cart);
        setCart(crt);
    }

    const swalConfirm = (e) => {
        confirmCreate('Actualizar inventario?', 'Confirmar para actualizar inventaro').then((confirmed) => {if (confirmed){updateOrder(e)}})
    }

    const updateOrder = async (e) => { 
        const { data } = await fetchPut({
            endpoint: major.updateOrder,
            params:{
                "cart": cart,
                "id_order": order
            }
        });
    };

    useEffect(() => {
        getOrderList();
    }, []);

    return (
        <div className='flex-container'>
            <FormCreateCart cart={cart} swalConfirm={swalConfirm} validate={true}/>
            <TableItemsTrainer data={items} shoping={shoping} updateTable={getItems}/>
        </div>
    )
}
