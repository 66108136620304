import React, { useEffect, useState } from 'react'
import { changePage } from '../../utils/functions';

export const TablePaginate = ({columns=[], rows, functionData, paginate=true,
    filter1 = false, filter1OptionDefault = 'Seleccione una opción', filter1Data = [], filter1OptionText = 'id'}) => {

    const [ page, setPage ] = useState(1);
    const [ pageMax, setPageMax ] = useState(1);
    const [ filter, setFilter ] = useState('');
    const [ totalPages, setTotalPages ] = useState(0);
 
    const getData = async () => {
        const data = await functionData(page, filter);

        if(paginate){
            setTotalPages(data.data.data.total);
            setPageMax(data.data.data.last_page);
        }
    };

    function movePage(pageChanged){
        const currentPage = changePage(pageChanged, pageMax);
        setPage(currentPage);
        functionData(currentPage, filter);
    }

    function filtering1(value){
        functionData(1, value)
    }

    useEffect(() => {getData(page);}, []);

    return (
        <div className='table'>
            {paginate && (
                <>
                    <div className='table-bar'>
                        <div className='table-bar-search'>
                            <form>

                                {filter1 && (
                                    <select onChange={(e) => filtering1(e.target.value)}>
                                    <option value={0}>{filter1OptionDefault}</option>
                                    {filter1Data.map((data) => (<option key={data.id} value={data[filter1OptionText]}>{data[filter1OptionText]}</option>))}
                                    </select>
                                )}

                                <input value={filter} onChange={(e) => setFilter(e.target.value)} placeholder='Buscar'/>
                                <button type='submit' onClick={(e) => getData(setPage(1))}>Buscar</button>
                            </form>
                        </div>
                    </div>
                </>
            )}

            <table>
                {paginate && (
                    <>
                        <thead className='table-navigate'>
                            <tr>
                                <td colSpan={100}>
                                    <button id='button-table-users-more' className={page <= 1 ? 'disabled' : ''} onClick={(e) => movePage(page - 1)}>{'<<<'}</button>

                                    {totalPages < 1 && <button>Sin resultados</button>}
                                    {totalPages >= 1 && <button>{page}</button>}

                                    <button id='button-table-users-less' className={page >= pageMax ? 'disabled' : ''} onClick={(e) => movePage(page + 1)}>{'>>>'}</button>
                                </td>
                            </tr>
                        </thead>
                    </>
                )}

            <thead>
                <tr>
                    {columns.map((column, index) => (<th key={index}>{column}</th>))}
                </tr>
            </thead>

                <tbody>{rows}</tbody>
            </table>
        </div>
    )
}
