import React from 'react'
import { NavbarTrainer } from '../components/navbars/NavbarTrainer'
import { ViewTrainerUserPieces } from '../components/views/ViewTrainerUserPieces'

export const PiecesUser = () => {
  return (
    <div>
        <NavbarTrainer/>
        <ViewTrainerUserPieces/>
    </div>
  )
}
