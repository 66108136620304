import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { FormEditClass } from '../components/forms/FormEditClass'

export const EditClass = () => {
  return (
    <div>
        <NavbarAdmin/>
        <FormEditClass/>
    </div>
  )
}
