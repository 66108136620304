import React, { useEffect, useState } from 'react'
import { major, stullerDefault } from '../../utils/constants'
import { clicked, fetchGet, fetchPost } from '../../utils/functions';
import { useCourse } from '../../utils/hooks';
import { imagePreview } from '../../utils/functions/imagePreview';
import { FormCreate } from './FormCreate';

export const FormCreateContent = ({ updateTable }) => {
    const { operations, handleOperations } = useCourse();
    const { levels, handleLevels } = useCourse();
    const { sublevels, handleSublevels } = useCourse();
    const [ opt, setOpt ] = useState(0);
    const [ lv, setLv ] = useState(0);
    const [ sublevel, handleSublevel ] = useState(0);

    const [ background, setBackground ] = useState('');
    const [ backgroundPreview, setBackgroundPreview ] = useState('');
    const [ miniature, setMiniature ] = useState('');
    const [ miniaturePreview, setminiaturePreview ] = useState('');

    const [ title, setTitle ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ position, setPosition ] = useState('');

    const getOperations = async () => {
        const { data } = await fetchGet({endpoint: major.operations});
        handleOperations(data.data.data)
    };

    const getLevels = async (opt) => {
        const { data } = await fetchGet({endpoint: major.levels + opt});
        handleLevels(data.data.data);
        setOpt(opt);
    };

    const getSublevels = async (lvl) => {
        const { data } = await fetchGet({endpoint: major.sublevels + lvl});
        handleSublevels(data.data.data);
        setLv(lvl);
    };

    const handleBackgroundChange = (event) => {
        const file = event.target.files[0];
        setBackground(file);  
        const prev = imagePreview(event);
        setBackgroundPreview(prev);   
    };

    const handleMiniatureChange = (event) => {
        const file = event.target.files[0];
        setMiniature(file);  
        const prev = imagePreview(event);
        setminiaturePreview(prev);   
    };

    const createContent = async (e) => {
        e.preventDefault();
        const { data, loading, error } = await fetchPost({
            endpoint: major.createContent,
            params:{

        },
        headers: {}
        });
    };

    useEffect(() => {
        getOperations();
    }, []);

    return (
        <FormCreate
            form={<>
                <select onChange={(e) => getLevels(e.target.value)} value={opt}>
                    <option value={0}>Selecciona una operación</option>
                    {operations.map((operation) => (<option key={operation.id} value={operation.id}>{operation.name}</option>))}
                </select>

                <select onChange={(e) => getSublevels(e.target.value)} value={lv}>
                    <option value={0}>Selecciona un nivel</option>
                    {levels.map((level) => (<option key={level.id} value={level.id}>{level.name}</option>))}
                </select>

                <select onChange={(e) => handleSublevel(e.target.value)} value={sublevel}>
                    <option value={0}>Selecciona un subnivel</option>
                    {sublevels.map((sublevel) => (<option key={sublevel.id} value={sublevel.id}>{sublevel.name}</option>))}
                </select>

                <label>
                    <input value={title}  placeholder='Título' onChange={(e) => (setTitle(e.target.value))}/>
                </label>

                <label>
                    <input value={description} placeholder='Descripción' onChange={(e) => (setDescription(e.target.value))}/>
                </label>

                <label>
                    <input value={position} placeholder='Posición' onChange={(e) => (setPosition(e.target.value))}/>
                </label>


                <div className='images-group'>
                    <div className='image-with-title'>
                        <h2>Fondo</h2>
                        <img src={backgroundPreview || stullerDefault}  onClick={() => clicked('image_content_background')}/>
                        <input id='image_content_background' type='file' onChange={handleBackgroundChange} style={{ display: 'none' }}/>
                    </div>
        
                    <div className='image-with-title'>
                        <h2>Miniatura</h2>
                        <img src={miniaturePreview || stullerDefault}  onClick={() => clicked('image_content_miniature')}/>
                        <input id='image_content_miniature' type='file' onChange={handleMiniatureChange} style={{ display: 'none' }}/>
                    </div>
                </div>
            </>}

            params={{
                "title": title,
                "sequence": position,
                "image": miniature,
                "background": background,
                "description": description,
                "id_sublevel": sublevel
            }}

            headers={{"Content-Type" : "multipart/form-data"}}
            endpoint={major.createContent}
            conditions={sublevel === '' || title === '' || position === ''}
            updateTable={updateTable}

            resetForm={[setBackground, setBackgroundPreview, setMiniature, setminiaturePreview, setTitle, setDescription, setPosition, setOpt, setLv, handleSublevel]}
        />
    )
}