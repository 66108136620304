import React, { useEffect, useState } from 'react'
import { fetchGet, getCookie } from '../../utils/functions';
import { major } from '../../utils/constants';
import { CakeChart } from '../charts/CakeChart';
import { BarChart } from '../charts/BarChart';
import { DoughnutChart } from '../charts/DoughnutChart';

export const GridRate = ({id=getCookie('id')}) => {

    const [ rate, setRate ] = useState(0);
    const [ data, setData ] = useState([]);
    const [ labels, setLabels ] = useState([]);

    const [ pieces, setPieces ] = useState([]);
    const [ titlePieces, setTitlePieces ] = useState([]);

    const [ asists, setAsists ] = useState([]);
    const [ asistsLables, setAsistsLabels ]= useState([]);

    const getRate = async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.getRateUser + id});
        setRate(data.data.data);
    };

    const getDefects= async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.getDefectsUser + id});

        const defectLabels = [];
        const defectData = [];
        Object.entries(data.data.data).forEach(([defect, count]) => {
            defectLabels.push(defect);
            defectData.push(count);
        });
        setLabels(defectLabels);
        setData(defectData);    
    };

    const getPieces= async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.getPiecesUser + id});

        const defectLabels = [];
        const defectData = [];
        Object.entries(data.data.data).forEach(([defect, count]) => {
            defectLabels.push(defect);
            defectData.push(count);
        });
        setTitlePieces(defectLabels);
        setPieces(defectData);
    };

    const getUserAsists= async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.getUserAsists + id});

        let rateDate = [data.data.data.asists, data.data.data.unasists];
        let rateLabels = ['Asistsencias', 'Faltas'];

        setAsists(rateDate);
        setAsistsLabels(rateLabels);
    }; 

    useEffect(() => {
        getRate();
        getDefects();
        getPieces();
        getUserAsists();
    }, [id]);

    return (
        <div className='grid grid-3 grid-chart'>
            <div className='progress-content'>
                <div className='percent-content'>
                    <div className='percent-content-image'></div>
                    <div className='percent-progress' style={{ height: `${rate}%` }}></div>
                    <p>{parseFloat(parseFloat(rate).toFixed(2))}%</p>
                </div>
                <h2>Calidad global</h2>
            </div>

            <div className='progress-content'>
                <div className='percent-content'>
                    <CakeChart data={pieces} labels={titlePieces}/>
                </div>
                <h2>FTT</h2>
            </div>

            <div className='progress-content'>
                <div className='percent-content'>
                    <DoughnutChart data={asists} labels={asistsLables}/>
                </div>
                <h2>Asistencias</h2>
            </div>

            <div className='progress-content' style={{ gridColumn: '1 / span 3' }}>
                <div className='percent-content'>
                    <BarChart data={data} labels={labels}/>
                </div>
                <h2>Defectos</h2>
            </div>

            <div className='progress-content'>
            </div>
        </div>
    )
}
