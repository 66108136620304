export function cartList(id, total, number, image, cart) {
    let cartUpdated = cart.map(item => 
        item.id === id ? { ...item, total: item.total + total } : item
    );

    if (!cart.some(item => item.id === id)) {
        if (total > 0) cartUpdated = [...cart, { id, total, number, image }];
    }

    cartUpdated = cartUpdated.filter(item => item.total > 0);

    return cartUpdated;
}