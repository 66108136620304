import React, { useEffect, useState } from 'react'
import { TablePaginate } from './TablePaginate'
import { baseURL } from '../../utils/constants/apis/major'
import { major, stullerDefault } from '../../utils/constants'
import { fetchGet, setCookie } from '../../utils/functions'
import { useNavigate } from 'react-router-dom'

export const TableItems = ({ data, updateTable }) => {
    const [ operations, setOperations ] = useState([]);
    const navigate = useNavigate();

    function cookieAndGo(name, cookieValie, route){
        setCookie({ name: name, value: cookieValie })
        navigate(route);
    }

    const getOperations = async () => {
        const { data } = await fetchGet({endpoint: major.operations});
        setOperations(data.data.data);
    };
  
    useEffect(() => {
        getOperations();
    }, []);

    return (
        <TablePaginate
            rows={data.map((item, index) => (
                <tr key={index}>
                    <td><img src={item.image ? baseURL + item.image : stullerDefault}/></td>
                    <td>{item.name}</td>
                    <td>{item.brand}</td>
                    <td>{item.number}</td>
                    <td>{item.pack}</td>
                    <td>{item.description}</td>

                    <td>
                        <button onClick={(e) => cookieAndGo('itemId', item.id, '/admin/item')} className='edit'>Editar</button>
                    </td>

                </tr>
            ))}
    
            columns={['Imagen', 'Nombre', 'Marca',  'Código', 'Paquete', 'Descripción', 'Acciones']}
            functionData={updateTable}

            filter1={true}
            filter1OptionDefault='Filtrar por Operación'
            filter1Data={operations}
            filter1OptionText='name'
        />
    )
}
