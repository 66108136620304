import React from 'react'

export const IframeWatchLink = ({ link, state, close }) => {
    return (
        <div className={`iframe-watch-link ${state ? 'show-iframe-watch-link' : ''}`}>

            <div className='options'>
                <button className='close'  onClick={() => close()}>X</button>
            </div>

            <div className='iframe'>
                <iframe src={link} frameborder="0"></iframe>
            </div>
        </div>
    )
}
