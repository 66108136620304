import React, { useState } from 'react'
import { FormCreateDefect } from '../forms/FormCreateDefect'
import { fetchGet } from '../../utils/functions';
import { major } from '../../utils/constants';
import { TableDefects } from '../tables/TableDefects';

export const ViewAdminDefects = () => {
    const [ defects, setDefects ] = useState([]);

    const getDefects = async (page, filter='') => {
        const pag = '?page=' + page;
        const { data, loading, error } = await fetchGet({
            endpoint: major.getDefectsByAdmin + pag,
            params:{"filter": filter}
        });
        setDefects(data.data.data.data);
        return data;
    };

    return (
        <div className='flex-container'>
            <FormCreateDefect updateTable={getDefects}/>
            <TableDefects data={defects} updateTable={getDefects}/>
         </div>
    )
}
