import React from 'react'
import { NavbarTrainer } from '../components/navbars/NavbarTrainer'
import { TableUserTrainer } from '../components/tables/TableUserTrainer'

export const Trainer = () => {
  return (
    <div>
        <NavbarTrainer/>
        <TableUserTrainer/>
    </div>
  )
}
