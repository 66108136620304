//export const baseURL = 'http://127.0.0.1:8000/';
export const baseURL = 'https://api.major-cej.com/public/';

export const login = baseURL + 'api/login';
export const createuser = baseURL + 'api/user';
export const validateUser = baseURL + 'api/user/validate/';
export const AdminUsers = baseURL + 'api/admin/users';
export const trainerUsers = baseURL + 'api/trainer/users';
export const AdminUser = baseURL + 'api/admin/user/';
export const foilData = baseURL + 'api/admin/foil/';
export const TrainerUser = baseURL + 'api/trainer/user/';
export const AdminUpdateUser = baseURL + 'api/admin/user/';

export const course = baseURL + 'api/course';
export const courses = baseURL + 'api/courses';
export const currentCourses = baseURL + 'api/courses/current';
export const trainers = baseURL + 'api/trainers';
export const operations = baseURL + 'api/opt/operations';
export const levels = baseURL + 'api/opt/levels/';
export const sublevels = baseURL + 'api/opt/sublevels/';


export const createContent = baseURL + 'api/contents/create';
export const contents = baseURL + 'api/contents';
export const userContents = baseURL + 'api/users/contents/';

export const createClass = baseURL + 'api/classes/create';
export const classes = baseURL + 'api/classes/';
export const userClasses = baseURL + 'api/users/classes/';
export const userClassFile = baseURL + 'api/users/class/';

export const userUnlockClass = baseURL + 'api/class/unlock';
export const nextClass = baseURL + 'api/class/next/';

export const userUnlockContent = baseURL + 'api/content/unlock';
export const nextContent = baseURL + 'api/content/next/';


export const backgroundContent = baseURL + 'api/contents/background/';

export const getSublevelDefects = baseURL + 'api/defects/';
export const createPieceDefect = baseURL + 'api/pieceDefect';
export const getPiecesDefects = baseURL + 'api/piecesDefects';

export const getCurrentListDates = baseURL + 'api/trainer/listDates/';
export const getCurrentListUsers = baseURL + 'api/trainer/listUsers/';
export const updateAsists = baseURL + 'api/asist/update/';

export const getContentsUserByTrainer = baseURL + 'api/trainer/contents/';
export const getClassesUserByTrainer = baseURL + 'api/trainer/classes/';

export const destroyUser = baseURL + 'api/users/destroy/';
export const destroyClass = baseURL + 'api/class/destroy/';
export const destroyContent = baseURL + 'api/content/destroy/';
export const destroyDefectPiece = baseURL + 'api/defectPiece/destroy/';

export const getProgrressByUser = baseURL + 'api/users/getByUser/';

export const getRateUser = baseURL + 'api/users/userRate/';
export const getScoops = baseURL + 'api/scoop';
export const getLastScoop = baseURL + 'api/scoop/last';
export const createScoop = baseURL + 'api/scoop/store';
export const updateContent = baseURL + 'api/content/update/';
export const getContent = baseURL + 'api/content/show/';

export const updateClass = baseURL + 'api/class/update/';

export const createDefect = baseURL + 'api/defects/create';
export const getDefectsByAdmin = baseURL + 'api/defectsByAdmin';

export const updateDefect = baseURL + 'api/defect/update/';
export const getDefect = baseURL + 'api/defect/get/';


export const sendNotification = baseURL + 'api/notification/send';
export const getNotificationsByUser = baseURL + 'api/user/notifications';

export const getClass = baseURL + 'api/class/';

export const createItem = baseURL + 'api/item/create';
export const getItems = baseURL + 'api/items';

export const getDataTrainer = baseURL + 'api/getDataTrainer/';

export const createOrder = baseURL + 'api/order/create';
export const getOrders = baseURL + 'api/orders/get';
export const getOrdersByTrainer = baseURL + 'api/orders/getByTrainer/';

export const getOrderList = baseURL + 'api/items/get/';

export const updateOrder = baseURL + 'api/order/update';

export const getFacture = baseURL + 'api/order/facture/';

export const getMajorAdministrations = baseURL + 'api/major/administration';
export const getItem = baseURL + 'api/item/';
export const updateItem = baseURL + 'api/item/update/';

export const getDefectsUser = baseURL + 'api/users/userDefects/';
export const getPiecesUser = baseURL + 'api/users/userPiecesCount/';

export const getProgressClassesByUser = baseURL + 'api/users/getProgressClasses/';
export const getRecognitionPiece = baseURL + 'api/recognition_piece/get';
export const createRecognitionPiece = baseURL + 'api/recognition_pieces/create';

export const getLastCourses = baseURL + 'api/lastCourses';
export const getRecognitionPiecesByOperation = baseURL + 'api/recognition_piece/getByOperation/';

export const createRecognition = baseURL + 'api/recognition/create';
export const getRecognitionPieceForEdit = baseURL + 'api/recognition_piece/show/';
export const updateRecognitionPiece = baseURL + 'api/recognition_piece/update/';
export const getSublevel = baseURL + 'api/getSublevel/';

export const validatePiece = baseURL + 'api/validatePiece/';
export const validateClase = baseURL + 'api/defect/validateClasses/';

export const bubbles = baseURL + 'api/bubbles';
export const newOrders = baseURL + 'api/orders/news';

export const getPoints = baseURL + 'api/pieceDefects/getPoints/';
export const deletePoints = baseURL + 'api/pieceDefects/deletePoints/';
export const classesWhithDefects = baseURL + 'api/defect/pieces/';

export const getRecognitionPieceImage = baseURL + 'api/class/getRecognitionImage/';
export const getTypeOfDefects = baseURL + 'api/defect/types/';

export const getPointsByType = baseURL + 'api/defect/points/';
export const getRecognitionsByClass = baseURL + 'api/class/getRecognitionsByClass/';


export const getLot = baseURL + 'api/classPieces/getLot/';
export const getPiecesByClass = baseURL + 'api/classPieces/getPiecesByClass/';

export const getDistribution = baseURL + 'api/distribution';

export const getRecognitions = baseURL + 'api/recognition/getRecognitions';

export const changeStatusRecognition = baseURL + 'api/recognition/changeStatus/';

export const getUserAsists = baseURL + 'api/user/asists/';
