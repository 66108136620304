import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { downloadQR, fetchGet, getCookie, modal, setCookie } from '../../utils/functions';
import { major, qrcode, stullerDefault } from '../../utils/constants';
import { TablePaginate } from './TablePaginate';
import { ModalQR } from '../modals/ModalQR';

export const TableUserTrainer = () => {
    const [ users, setUsers ] = useState([]);
    const navigate = useNavigate();
    const trainerId = getCookie('id');

    const [qrUser, setQrUser] = useState('');

    function cookieAndGo(name, cookieValie, page){
        setCookie({ name: name, value: cookieValie })
        navigate(page);
    }

    const getUsers = async (page, filter='') => {
        const pag = '?page=' + page;
        const { data, loading, error } = await fetchGet({
            endpoint: major.trainerUsers + pag,
            params:{
                "filter": filter,
                "id_trainer": trainerId
            }
        })
        setUsers(data.data.data.data);
        return data;
    };

    function openQrCode(value){
        setQrUser(value);
        modal('modal-qr-code');
    }

  return (
    <>
    <TablePaginate
        rows={users.map((user) => (
            <tr key={user.id}>
                <td><img src={user.image && user.image !== '' ? major.baseURL + user.image : stullerDefault}/></td>
                <td>{user.name} {user.paternal_surename} {user.maternal_surename}</td>
                <td>{user.operation} {user.sublevel}</td>
                <td>{user.username}</td>
                <td><div className='progress-bar'><div className='progress' style={{ width: `${(user.asists * 100) / user.total_asists}%` }}></div></div></td>
                <td><div className='progress-bar'><div className='progress' style={{ width: `${(user.classes * 100) / user.total_classes}%` }}></div></div></td>

                <td>
                    <img src={qrcode} onClick={() => openQrCode(user.username)}/>
                </td>

                <td>
                    <button onClick={(e) => cookieAndGo('userId', user.id, '/trainer/user')}>Calificar</button>
                    <button onClick={(e) =>cookieAndGo('userId', user.id, '/trainer/notifications')}>Mensaje</button>
                </td>
            </tr>
        ))}

        columns={['Foto', 'Nombre', 'Curso', 'Usuario', 'Asistencia', 'Avance', 'QR', 'Acciones']}
        functionData={getUsers}
    />

    <ModalQR value={qrUser}/>
    </>
  )
}