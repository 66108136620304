import { useState } from "react";

export const useUser = () => {
    const [name, setName] = useState('');
    const handleName = (value) => setName(value);

    const [paternalSurename, setPaternalSurename] = useState('');
    const handlePaternalSurename = (value) => setPaternalSurename(value);

    const [maternalSurename, setMaternalSurename] = useState('');
    const handleMaternalSurename = (value) => setMaternalSurename(value);

    const [birthdate, setBirthdate] = useState('');
    const handleBirthdate = (value) => setBirthdate(value);

    const [image, setImage] = useState('');
    const handleImage = (value) => setImage(value);

    const [courses, setCourses] = useState([]);
    const handleCourses = (value) => setCourses(value);
  
    return{
        name,
        handleName,
        paternalSurename,
        handlePaternalSurename,
        maternalSurename,
        handleMaternalSurename,
        birthdate,
        handleBirthdate,
        image,
        handleImage,
        courses,
        handleCourses
    }
}