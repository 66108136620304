import React, { useEffect, useState } from 'react'
import { clicked, fetchGet, fetchPut, getCookie } from '../../utils/functions';
import { major, stullerDefault } from '../../utils/constants';
import { imagePreview } from '../../utils/functions/imagePreview';

export const FormEditItem = () => {
    const itemId = getCookie('itemId');
    const [ name, setName ] = useState('')
    const [ brand, setBrand ] = useState('');
    const [ code, setCode ] = useState('');
    const [ pack, setPack ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ image, setImage ] = useState('');
    const [ preview, setPreview ] = useState('');
    const [ operation, setOperation ] = useState('');
    const [ operations, setOperations ] = useState([]);

    const getOperations = async () => {
      const { data } = await fetchGet({endpoint: major.operations});
      setOperations(data.data.data);
    };

    const getUser = async () => {
      const { data, loading, error } = await fetchGet({endpoint: major.getItem + itemId});
      setName(data.data.data.name);
      setCode(data.data.data.number);
      setBrand(data.data.data.brand);
      setPack(data.data.data.pack);
      setDescription(data.data.data.description);
      setImage(data.data.data.image);
      setOperation(data.data.data.id_operation);
      if(data.data.data.image){setPreview(major.baseURL + data.data.data.image);}
    };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);  
    const prev = imagePreview(event);
    setPreview(prev);   
  };

  const FormEditItem = async (e) => {
    e.preventDefault();

    const { data, loading, error } = 
    await fetchPut({
        endpoint: major.updateItem + itemId,
        params:{
          "name": name,
          "brand": brand,
          "number": code,
          "pack": pack,
          "description": description,
          "id_operation": operation,
          "image": image
        },
        headers:{"Content-Type" : "multipart/form-data"}
    });
  };

  useEffect(() => {
    getOperations();
    if(itemId != null){getUser();};
  }, []);
  
    return (
      <div className='form-flex'>    
          <form >
            <h2>EDITAR ITEM</h2>

            <img src={preview || stullerDefault} onClick={() => clicked('edit-item-image')} />
            <input type="file" id='edit-item-image' onChange={handleImageChange} style={{ display: 'none' }} />

            <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder='Nombre'/>
            <input value={brand} onChange={(e) => setBrand(e.target.value)} type="text" placeholder='Marca'/>
            <input value={code} onChange={(e) => setCode(e.target.value)} type="text" placeholder='Código'/>
            <input value={pack} onChange={(e) => setPack(e.target.value)} type="text" placeholder='Cantidad del paquete'/>
            <input value={description} onChange={(e) => setDescription(e.target.value)} type="text" placeholder='Descripción'/>

            <select value={operation} onChange={(e) => setOperation(e.target.value)}>
              <option value={0}>Seleccione una operación</option>
              {operations.map((opt) => (<option key={opt.id} value={opt.id}>{opt.name}</option>))}
            </select>

            <button type='submit' onClick={(e) => FormEditItem(e)}>Editar</button>
          </form>
      </div>
    );
}
