import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { ViewAdminClasses } from '../components/views/ViewAdminClasses';

export const AdminClasses = () => {
  return (
    <div>
        <NavbarAdmin/>
        <ViewAdminClasses/>
    </div>
  )
}
