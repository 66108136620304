import React, { useEffect } from 'react'
import { stullerBirthdate } from '../../utils/constants'
import { deleteCookie, modal } from '../../utils/functions'

export const ModalBirthdate = ({ name='', login}) => {

    function happyBirthdate(){
        deleteCookie('login');
    }

    useEffect(() => {
        if(login){
            modal('modal-birthdate');
            happyBirthdate();
        }
    }, []);

    return (
      <div id='modal-birthdate' className='modal'>
          <div className='header'>
            <div className='close' onClick={() => modal('modal-birthdate')}>X</div>
          </div>

          <div className='image'><img src={stullerBirthdate}/></div>
          <div className='title'><p>¡Felicidades {name}!</p></div>
          <div className='text'><p>Stuller México te desea feliz cumpleaños</p></div>
      </div>
    )
}
