import React, { useEffect, useRef, useState } from 'react'
import { TablePaginate } from './TablePaginate'
import { baseURL } from '../../utils/constants/apis/major'
import { major, stullerDefault } from '../../utils/constants'
import { fetchGet } from '../../utils/functions'

export const TableItemsTrainer = ({ data, updateTable, shoping }) => {
    const [ operations, setOperations ] = useState([]);

    const getOperations = async () => {
        const { data } = await fetchGet({endpoint: major.operations});
        setOperations(data.data.data);
    };
  
    useEffect(() => {
        getOperations();
    }, []);

    
    const [time, setTime] = useState(0);
    const intervalId = useRef(null);
    
    const down = () => {
        // Reiniciar el contador de tiempo
        setTime(0);

        // Si ya hay un intervalo en ejecución, no hacer nada
        if (intervalId.current !== null) return;

        // Iniciar el intervalo que incrementa el contador cada segundo
        intervalId.current = setInterval(() => {
            // Usar la forma funcional de setTime para asegurar que el estado se actualice correctamente
            setTime(prevTime => prevTime + 1);
        }, 250);
    };
    
    const up = (itemID, itemCount, itemNumber, itemImage) => {
        // Detener el intervalo
        if (intervalId.current !== null) {
            clearInterval(intervalId.current);
            intervalId.current = null;
            
            // Llamar a shoping con el tiempo acumulado como multiplicador
            shoping(itemID, itemCount * time, itemNumber, itemImage);

            // Opcional: Reiniciar el contador de tiempo aquí si es necesario
            setTime(0);
        }
    };

    return (
        <TablePaginate
            rows={data.map((item, index) => ( 
                <tr key={index}>
                    <td><img src={item.image ? baseURL + item.image : stullerDefault}/></td>
                    <td>{item.name}</td>
                    <td>{item.brand}</td>
                    <td>{item.number}</td>

                    <td>
                        {item.pack != null &&
                        <button className='button-cart destroy'
                        onClick={(e) => shoping(item.id, -item.pack, item.number, item.image)}
                        onTouchStart={(e) => down(item.id, -item.pack, item.number, item.image)}
                        onTouchEnd={(e) => up(item.id, -item.pack, item.number, item.image)}
                        >- {item.pack}</button>}
                        
                        <button className='button-cart destroy'
                        onClick={(e) => shoping(item.id, -1, item.number, item.image)}
                        onTouchStart={(e) => down(item.id, -1, item.number, item.image)}
                        onTouchEnd={(e) => up(item.id, -1, item.number, item.image)}
                        >-</button>

                        <button className='button-cart edit'
                        onClick={(e) => shoping(item.id,  1, item.number, item.image)}
                        onTouchStart={(e) => down(item.id,  1, item.number, item.image)}
                        onTouchEnd={(e) => up(item.id,  1, item.number, item.image)}
                        >+</button>

                        {item.pack != null &&
                        <button className='button-cart edit'
                        onClick={(e) => shoping(item.id, item.pack, item.number, item.image)}
                        onTouchStart={(e) => down(item.id, item.pack, item.number, item.image)}
                        onTouchEnd={(e) => up(item.id, item.pack, item.number, item.image)}
                        >+ {item.pack}</button>}

                    </td>
                </tr>
            ))}
            
    
            columns={['Imagen', 'Nombre', 'Marca', 'Código', 'Acciones']}
            functionData={updateTable}

            filter1={true}
            filter1OptionDefault='Filtrar por Operación'
            filter1Data={operations}
            filter1OptionText='name'
        />
    )
}