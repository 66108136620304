import React, { useEffect, useRef, useState } from 'react';
import { fetchGet, fetchPost, getCookie, modal } from '../../utils/functions';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { qrcode } from '../../utils/constants';
import Swal from 'sweetalert2';

export const ModalScanner = ({ close, setClose }) => {
    const [scannerActive, setScannerActive] = useState(false);
    const [ totalJobs, setTotalJobs ] = useState(0);
    const scannerRef = useRef(null);

    const id_user = getCookie('id');

    useEffect(() => {
        if (scannerActive) {
            scannerRef.current = new Html5QrcodeScanner('reader', {
                qrbox: { width: 600, height: 200 },
                fps: 5,
            });
            scannerRef.current.render(success, error);
        }

        return () => {
            if (scannerRef.current) {
                scannerRef.current.clear().catch(err => console.warn('Failed to clear scanner', err));
                scannerRef.current = null;
            }
        };
    }, [scannerActive]);

    useEffect(() => {
        GetJobs();
        startScanner();
    }, [])

    const startScanner = () => {
        setScannerActive(true);
    };

    const stopScanner = () => {
        setScannerActive(false);
    };

    const success = async (result) => {
        const { data, loading, error } = await fetchPost({endpoint: `https://api.major-cej.com/public/api/job/create/${id_user}/${result}`,});

        CloseModal();

        Swal.fire({
            title: 'Guardado exitoso',
            text: 'Job guardado con éxito',
            toast: true,
        })
    };

    const error = (err) => {
        console.warn(err);
    };

    const CloseModal = () => {
        setScannerActive(false);
        setClose(false)
    }

    const GetJobs = async () => {
        const { data, loading, error } = await fetchGet({endpoint: `https://api.major-cej.com/public/api/jobs/${id_user}`,});
        setTotalJobs(data.data);
    }

    return (
        <div id='modal-scanner' className='modal modal-show'>
            <div className='header'>
                <h2>Scanner</h2>
                <div className='close' onClick={() => CloseModal()}>X</div>
            </div>

            <div className='content'>
                <div className='qr-code'><div id='reader'></div></div>

                <div className="counter">
                    <span><p>Jobs actuales: {totalJobs}</p></span>
                </div>
            </div>
        </div>
    );
};
