import React from 'react'
import { confirmCreate, fetchPost, toastComplete } from '../../utils/functions';

export const FormCreate = ({ 
    form,
    submit='Crear',
    title='CREAR',
    conditions = '',
    swal_title='¿Crear?',
    swal_text='Confirma para crear registro',
    endpoint,
    params=[],
    headers=[],
    updateTable,
    resetForm =[],
}) => {

    const swalConfirm = (e) => {
        if (conditions){toastComplete()}
        else {confirmCreate(swal_title, swal_text).then((confirmed) => {if (confirmed){FormCreate(e)}})}
    }

    const FormCreate = async (e) => {
        e.preventDefault();
        const { data, loading, error } =  await fetchPost({ endpoint: endpoint, params:params, headers });
        clear();
        
        if(updateTable){
            updateTable();
        }
    };

    function clear(){
        resetForm.forEach(element => { element(''); });
    }

    return (
      <div className='form-flex'>
          <form>

            <h2>{title}</h2>
              {form}
              <button type='submit' onClick={(e) => swalConfirm(e)}>{submit}</button>

          </form>
      </div>
    )
}
