import React, { useEffect, useState } from 'react'
import { clicked, fetchGet, fetchPut, getCookie } from '../../utils/functions';
import { major, stullerDefault } from '../../utils/constants';
import { imagePreview } from '../../utils/functions/imagePreview';

export const FormEditUser = () => {
    const userId = getCookie('userId');

    const [ name, setName ] = useState('')
    const [ paternalSurename, setPaternalSurename ] = useState('');
    const [ maternalSurename, setMaternalSurename ] = useState('');
    const [ birthdate, setBirthdate ] = useState('');
    const [ idCourse, setIdCourse ] = useState('');
    const [ courses, setCourses ] = useState([]);
    const [ image, setImage ] = useState('');
    const [ preview, setPreview ] = useState('');
    const [ gallery, setGallery ] = useState('');
    const [ admission, setAdmission ] = useState('');
    const [ employNumber, setEmployNumber ] = useState('');

    const getCourses = async () => {
      const { data } = await fetchGet({endpoint: major.currentCourses});
      setCourses(data.data.data);
    };

    const getUser = async () => {
      const { data, loading, error } = await fetchGet({endpoint: major.AdminUser + userId});
      setName(data.data.data.name);
      setPaternalSurename(data.data.data.paternal_surename);
      setMaternalSurename(data.data.data.maternal_surename);
      setIdCourse(data.data.data.id_course);
      setBirthdate(data.data.data.birthdate);
      setImage(data.data.data.image);
      setAdmission(data.data.data.admission);
      setEmployNumber(data.data.data.employ_number);
      if(data.data.data.image){setPreview(major.baseURL + data.data.data.image);}
    };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);  
    const prev = imagePreview(event);
    setPreview(prev);   
  };

  const FormUpdateUser = async (e) => {
    e.preventDefault();

    const { data, loading, error } = 
    await fetchPut({
        endpoint: major.AdminUpdateUser + userId,
        params:{
          "name": name,
          "paternal_surename": paternalSurename,
          "maternal_surename": maternalSurename,
          "id_course": idCourse,
          "birthdate": birthdate,
          "gallery": gallery,
          "image": image,
          "admission": admission,
          "employ_number": employNumber

        },
        headers:{"Content-Type" : "multipart/form-data"}
    });
  };

  useEffect(() => {
    getCourses();
    if(userId != null){getUser();};
  }, []);
  
    return (
      <div className='form-flex'>    
          <form >
            <h2>EDITAR USUARIO</h2>

              <img src={preview || stullerDefault}  onClick={() => clicked('image_admin_user')}/>
              <input id='image_admin_user' type='file' onChange={handleImageChange} style={{ display: 'none' }}/>
              
              <input value={name} placeholder='Nombre(s)' onChange={(e) => (setName(e.target.value))}/>
              <input value={paternalSurename} placeholder='Apellido Paterno' onChange={(e) => (setPaternalSurename(e.target.value))}/>
              <input value={maternalSurename} placeholder='Apellido Materno' onChange={(e) => (setMaternalSurename(e.target.value))}/>
              <input value={employNumber} placeholder='Número de empleado' onChange={(e) => (setEmployNumber(e.target.value))}/>
              <label><span>Fecha:</span><input value={birthdate} type='date' onChange={(e) => (setBirthdate(e.target.value))}/></label>
              <label><span>Ingreso:</span><input value={admission} type='date' onChange={(e) => (setAdmission(e.target.value))}/></label>


              <select value={idCourse} onChange={(e) => setIdCourse(e.target.value)}>
                <option value={0}>Seleccione un curso</option>
                {courses.map((course) => (<option key={course.id} value={course.id}>{course.operation} {course.sublevel} {course.start} - {course.end}</option>))}
              </select>

              <input value={gallery} placeholder='Galeria' onChange={(e) => (setGallery(e.target.value))}/>

              <button type='submit' onClick={(e) => FormUpdateUser(e)}>Editar</button>
          </form>
      </div>
    );
}
