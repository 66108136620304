import React from 'react'
import { baseURL } from '../../utils/constants/apis/major'
import { setCookie } from '../../utils/functions';
import { useNavigate } from 'react-router-dom';

export const FormCreateCart = ({ cart, swalConfirm, validate=false }) => {
    const navigate= useNavigate();

    return (
        <div className='form-flex form-cart'>

            <h2>Carrito</h2>

            {cart.map((item, index) => (
                <div key={index} className='cart'>
                    <div><img src={baseURL + item.image}/></div>
                    <div>{item.number}</div>
                    <div>{item.total}</div>
                </div>
            ))}

            <button className='edit' onClick={(e) => swalConfirm(e)}>Enviar solicitud</button>
            {validate && <button onClick={(e) =>navigate('/admin/order/pdf')}>Firmar solicitud</button>}
        </div>
    )
}
