import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJs, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

export const BarChart = ({ data = [], labels = [] }) => {
    // Registramos los componentes necesarios para la gráfica de barras
    ChartJs.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const colors = [
        'rgba(255, 159, 64, 0.5)', // Naranja
        'rgba(233, 30, 99, 0.5)', // Rosa fuerte
        'rgba(0, 188, 212, 0.5)', // Cian
        'rgba(205, 220, 57, 0.5)', // Lima
        'rgba(96, 125, 139, 0.5)', // Azul grisáceo
        'rgba(255, 87, 34, 0.5)', // Rojo naranja
        'rgba(63, 81, 181, 0.5)', // Azul índigo
        'rgba(158, 158, 158, 0.5)', // Gris
        'rgba(233, 30, 99, 0.5)', // Rosa fuerte de nuevo
        'rgba(156, 39, 176, 0.5)', // Púrpura
    ];

    // Función para obtener colores aleatorios de la lista de colores
    const getColors = () => {
        let shuffledColors = [...colors];
        for (let i = shuffledColors.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledColors[i], shuffledColors[j]] = [shuffledColors[j], shuffledColors[i]];
        }
        return shuffledColors.slice(0, data.length); // Aseguramos devolver solo la cantidad necesaria de colores
    };

    const datasets = {
        labels: labels,
        datasets: [{
            label: 'Dataset',
            data: data,
            backgroundColor: getColors(),
            borderColor: '#2f3238',
            borderWidth: 1,
        }],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: { position: 'top' },
            title: {
                display: false,
                text: 'Custom Chart Title'
            },
        },
    };

    return (
        <div className='chart chart-bar'>
            <Bar data={datasets} options={options} />
        </div>
    );
}
