import React from 'react'
import { NavbarTrainer } from '../components/navbars/NavbarTrainer'
import { FormCreateNotification } from '../components/forms/FormCreateNotification'

export const TrainerSendMessage = () => {
  return (
    <div>
        <NavbarTrainer/>
        <FormCreateNotification/>
    </div>
  )
}
