import React, { useEffect, useState } from 'react'
import { baseURL } from '../../utils/constants/apis/major';
import { modal } from '../../utils/functions';
import { eye, stullerDefault } from '../../utils/constants';
import { IframeWatchLink } from '../iframes/IframeWatchLink';

export const ModalOptions = ({ title='Seleccionar opción', data=[], id='modal', callFunction, watch=false }) => {
  const [ link, setLink ] = useState('');
  const [showIframe, setShowIframe] = useState(false);

  function getOption(value, image){
    callFunction(value, image);
    modal(id);
  }

  function watching(l){
    setLink(l);
    setShowIframe(!showIframe);
  }

  return (
    <>
      <div id={id} className='modal'>
        <div className='header'>
          <h2>{title}</h2>
          <div className='close' onClick={() => modal(id)}>X</div>
        </div>
    
        <div className='content'>
          {data.map((dat, index) => (
            <div key={index} style={{ opacity: dat.hide ? 0.5 : 1 }}>
              <div onClick={() => !dat.hide && getOption(dat.id, dat.image)}>
                <h2>{dat.title || dat.name}</h2>
                <img src={dat.image ? baseURL + dat.image : stullerDefault}/>
              </div>
          
              <div className='watch'>
                {watch && dat.watch && 
                  <img src={eye} onClick={() => watching(dat.watch)}/>
                }
              </div>
          </div>
          ))}
        </div>
      </div>

      {watch && 
         <IframeWatchLink link={link} state={showIframe} close={watching}/>
      }      
    </>    
  )
}
