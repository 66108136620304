import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { fetchGet, goView } from '../../utils/functions';
import { major, stullerDefault } from '../../utils/constants';
import { TablePaginate } from '../tables/TablePaginate';
import { FormCreateUser } from '../forms/FormCreateUser';
import { TableUsersAdmin } from '../tables/TableUsersAdmin';

export const ViewAdminUsers = () => {
    const [ users, setUsers ] = useState([]);
  
    const getUsers = async (page, filter='') => {
      const pag = '?page=' + page;
      const { data, loading, error } = await fetchGet({endpoint: major.AdminUsers + pag, params:{"filter": filter}})
      setUsers(data.data.data.data)
      return data;
    };
  
    return (
        <div className='flex-container'>
            <FormCreateUser updateTable={getUsers}/>
            <TableUsersAdmin data={users} updateTable={getUsers}/>
        </div>
    )
  }