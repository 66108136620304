import React, { useEffect, useState } from 'react'
import { FormCreate } from './FormCreate'
import { fetchGet, getCookie } from '../../utils/functions';
import { major } from '../../utils/constants';

export const FormCreateNotification = () => {
    const[ message, setMessage ] = useState('');
    const[ sendCourse, setSendCourse ] = useState(false);
    const[ course, setCourse ] = useState('');
    const toUser = getCookie('userId');
    const by = getCookie('id');

    const getUser = async (e) => {
        const { data } = await fetchGet({endpoint: major.TrainerUser + toUser});
        setCourse(data.data.data.id_course)
    };

    useEffect(() => {
        getUser();
    }, []);

    return (
        <FormCreate
            form={<>
                <textarea placeholder='Escribir mensaje' value={message}  onChange={(e) => setMessage(e.target.value)}></textarea>

                <div className='checkbox-content'>
                    <span>Enviar a todo el curso</span>
                    <input type='checkbox' checked={sendCourse} onChange={(e) => setSendCourse(e.target.checked)} />
                </div>
            </>}

            params={{
                "message": message,
                "by": by,
                "to_user": toUser,
                "to_course": course,
                "send_course": sendCourse
            }}

            endpoint={major.sendNotification}
            conditions={message === ''}

            resetForm={[setMessage]}
        />
    )
}
