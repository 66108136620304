import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { FormEditContent } from '../components/forms/FormEditContent'

export const EditContent = () => {
  return (
    <div>
        <NavbarAdmin/>
        <FormEditContent/>
    </div>
  )
}
