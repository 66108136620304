import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { ViewRecognitionPieces } from '../components/views/ViewRecognitionPieces'

export const RecognitionPieces = () => {
    return (
      <div>
          <NavbarAdmin />
          <ViewRecognitionPieces/>
      </div>
    )
}
