import Swal from "sweetalert2";

export function toastLoading(){
    const toast = Swal.fire({
        icon: 'info',
        title: 'Cargando...',
        toast: true,
        showConfirmButton: false,
        didOpen: () => {
            Swal.showLoading();
        }
    });
    return toast;
}

export function toastComplete(){
    const toast = Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Complete todos los campos',
        toast: true,
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
    })
    return toast;
}

export function toastPost(message, code){
    const icon = code === 500 ? 'error' : 'success';

    const toast = Swal.fire({
        icon: icon,
        title: message,
        toast: true,
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
    })
    return toast;
}

export function confirmCreateCourse() {
    return Swal.fire({
        icon: 'info',
        title: '¿Crear curso?',
        text: 'Una vez creado el curso no se podrá modificar',
        toast: true,
        showCancelButton: true    
    }).then(response => {
        return response.isConfirmed;
    });
}

export function confirmCreateUser() {
    return Swal.fire({
        icon: 'info',
        title: '¿Crear usuario?',
        text: 'Una vez creado el usuario no se podrá eliminar',
        toast: true,
        showCancelButton: true    
    }).then(response => {
        return response.isConfirmed;
    });
}

export function confirmCreate(title, text) {
    return Swal.fire({
        icon: 'info',
        title: title,
        text: text,
        toast: true,
        showCancelButton: true    
    }).then(response => {
        return response.isConfirmed;
    });
}

export function toastBirthdate(text) {
    return Swal.fire({
        toast: true,
        title: 'Feliz cumpleaños ' + text,
        timer: 5000,
        showConfirmButton: false,
    })
}