import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { fetchGet, getCookie, loadLandbotScript, modal } from '../../utils/functions';
import {  feedback, home, major, progress, quality, scanner as iconScanner, serve, stullerManual } from '../../utils/constants';
import { Conffeti } from '../animations/Conffeti';
import { Carousel } from '../carousel/Carousel';
import { UserOptions } from './UserOptions';
import { ModalBirthdate } from '../modals/ModalBirthdate';
import { ModalScanner } from '../modals/ModalScanner';

export const NavbarUser = () => {
    const [ name, setName ] = useState('');
    const [ image, setImage ] = useState('');
    const [ book, setBook ] = useState('');
    const [ scoop, setScoop ] = useState('');
    const [ birthdate, setBirthdate ] = useState(false);
    const [ scanner, setScanner ] = useState(false);
    const [ gallery, setGallery ] = useState('');
    const navigate  = useNavigate();
    const id = getCookie('id');
    const log = getCookie('login');

    const validate = async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.validateUser + id});
        if(data.data.data.role != 100){navigate('/')}
        setName(data.data.data.name);
        setImage(data.data.data.image);
        setBook(data.data.data.book);
        setGallery(data.data.data.gallery);

        const today = new Date();
        const date = today.toISOString().split('T')[0];
        if(data.data.data.birthdate == date){
            setBirthdate(true)
        }
    };

    const getLastScoop = async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.getLastScoop});
        if(data.data.data){ setScoop(data.data.data.link) }
    };

    useEffect(() => {
        loadLandbotScript();
        getLastScoop();
        if(typeof(id) == 'undefined'){ navigate('/') }
        if(typeof(id) == 'string'){ validate(); }
    }, []);

    const OpenScan = () => {
        setScanner(true);
    }

    return (
        <nav>
            {birthdate && (
                <>
                    <Conffeti />
                    <Carousel text={`Felicidades ${name} te desea Stuller México`} />
                    <ModalBirthdate name={name} login={log}/>
                </>
            )}

            <div>
                <div className='logos'>
                    <img src={serve}/>
                    <p>MAESTRO JOYERO ORFEBRE</p>
                </div>

                <div className='buttons'>
                    <div onClick={() => OpenScan()} className='info views'><img src={iconScanner} className='invert-color'/><p>Scanner</p></div>
                    <div onClick={() => navigate('/feedback')} className='info views'><img src={feedback} className='invert-color'/><p>Feedback</p></div>
                    <div onClick={() => navigate('/rate')} className='info views'><img src={quality} className='invert-color'/><p>Resultados</p></div>
                    <div onClick={() => navigate('/progress')} className='info views'><img src={progress} className='invert-color'/><p>Progreso</p></div>
                    <div onClick={() => navigate('/menu')} className='info views'><img src={home} className='invert-color'/><p>Inicio</p></div>
                    <UserOptions image={image} name={name} user={true}/>
                </div>
            </div>

            <iframe id='book' className='book' align="center" src={book} ></iframe>
            <iframe id='scoop' className='book' align="center" src={scoop} ></iframe>


            <div className='helps'>
                { scoop && <button onClick={() => modal('scoop')}><p>New</p></button> }
                <button onClick={() => modal('book')}><img src={stullerManual}/></button>
            </div>

            { scanner &&  <ModalScanner close={scanner} setClose={setScanner} /> }

        </nav>
    )
}
