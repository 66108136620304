import React from 'react'
import { NavbarUser } from '../components/navbars/NavbarUser'
import { GridContents } from '../components/grids/GridContents'
import { Conffeti } from '../components/animations/Conffeti'

export const Menu = () => {
  return (
    <div>
        <NavbarUser/>
        <GridContents/>
    </div>
  )
}
