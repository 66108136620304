import React from 'react'
import { modal } from '../../utils/functions'
import QRCode from 'react-qr-code'

export const ModalQR = ({value=''}) => {
  return (
    <div id='modal-qr-code' className='modal'>
        <div className='header'>
          <div className='close' onClick={() => modal('modal-qr-code')}>X</div>
        </div>
    
        <div className='content'>
          <QRCode value={value} size={240}/>
        </div>
    </div>
  )
}
