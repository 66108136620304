import React, { useEffect, useState } from 'react'
import { confirmCreate, fetchGet, fetchPost, getCookie } from '../../utils/functions';
import { major } from '../../utils/constants';
import { TableItemsTrainer } from '../tables/TableItemsTrainer';
import { FormCreateCart } from '../forms/FormCreateCart';
import { cartList } from '../../utils/functions/cart';

export const ViewTrainerItems = () => {
    const [ items, setItems ] = useState([]); 
    const [ cart, setCart ] = useState([]);
    const [ trainer, setTrainer ] = useState('');
    const [ operation, setOperation ] = useState('');
    const id = getCookie('id');

    const getDataTrainer = async (e) => {
        const { data } = await fetchGet({endpoint: major.getDataTrainer + id});
        setTrainer(data.data.data.trainer);
        setOperation(data.data.data.operation);
    };

    const getItems = async (page, filter='') => {
        const pag = '?page=' + page;
        const { data, loading, error } = await fetchGet({
            endpoint: major.getItems  + pag,
            params:{"filter": filter}
        });

        setItems(data.data.data.data);
        return data;
    };


    function shoping(id, total, number, image) {
        let crt = cartList(id, total, number, image, cart);
        setCart(crt);
    }
    

    const swalConfirm = (e) => {
        confirmCreate('¿Crear inventario?', 'Confirmar para crear inventaro').then((confirmed) => {if (confirmed){FormCreate(e)}})
    }

    const FormCreate = async (e) => {
        e.preventDefault();
        const { data, loading, error } =  await fetchPost({
            endpoint: major.createOrder,
            params:{
                "id_trainer": trainer,
                "id_operation": operation,
                "cart": cart
            }
        });    
    };

    useEffect(() => {
        getDataTrainer();
    }, []);

    return (
        <div className='flex-container'>
            <FormCreateCart cart={cart}  swalConfirm={swalConfirm}/>
            <TableItemsTrainer data={items} shoping={shoping} updateTable={getItems}/>
        </div>
    )
}
