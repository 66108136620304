import React, { useState } from 'react'
import { FormCreateDefectPiece } from '../forms/FormCreateDefectPiece'
import { TablePiecesDefects } from '../tables/TablePiecesDefects'
import { fetchGet, getCookie } from '../../utils/functions';
import { major } from '../../utils/constants';
import { FormSelectDefectPiece } from '../forms/FormSelectDefectPiece';

export const ViewTrainerUserPieces = () => {
    return (
        <FormSelectDefectPiece/>
    )
}