import React, { useEffect, useState } from 'react'
import { fetchGet, getCookie, modal } from '../../utils/functions';
import { major } from '../../utils/constants';
import { baseURL } from '../../utils/constants/apis/major';

export const ModalFeedback = ({ classId }) => {
    //const classId = getCookie('class');
    const userId = getCookie('id');
    const [ image, setImage ] = useState('');
    const [ types, setTypes ] = useState([]);
    const [ defects, setDefects ] = useState([]);

    const getImage = async (e) =>{ 
        const { data, loading, error } = await fetchGet({ endpoint: major.getRecognitionPieceImage + classId});
        setImage(data.data.data.image)
    };

    const getTypes = async (e) =>{ 
        const { data, loading, error } = await fetchGet({ endpoint: major.getTypeOfDefects + userId + '/' + classId});
        setTypes(data.data.data);
    };

    const getPoints = async (id_defects) =>{ 
        const { data, loading, error } = await fetchGet({ 
        endpoint: major.getPointsByType + userId + '/' + classId,
        params:{
            "id_defects": id_defects
        }});

        setDefects(data.data.data);
    };

    function filt(){
        let checks = document.querySelectorAll('.filter');
        let search = [];

        checks.forEach(item => {
            if(item.checked) search.push(item.value)
        });

        getPoints(search);
    }

    function filtsOn(){
        let checks = document.querySelectorAll('.filter');
        let search = [];

        checks.forEach(item => {
            item.checked = true;
            search.push(item.value);
        });

        getPoints(search);
    }

    useEffect(() => {
        getImage();
        getTypes();
    }, [classId]);

    useEffect(() => {
        filtsOn();
    }, [types]);

    const colors = [
        '#00FFFF',
        '#7FFFD4',
        '#FF7F50',
        '#FFD700',
        '#FFB6C1',
        '#87CEEB',
        '#FFFF00',
        '#0000FF',
        '#8B008B',
        '#DAA520'
    ];


    return (
        <div id='modal-feedback' className='modal'>
          <div className='header'>
            <div className='close' onClick={() => modal('modal-feedback')}>X</div>
          </div>

            <div className='content'>
                <div className='image-coords-user'>
                    <img src={baseURL + image}/>

                    {defects.map((defect, index) => (

                    <div key={index} 
                        className='point' 
                        style={{
                            top: `${defect.y_axis - 10}px`, 
                            left: `${defect.x_axis - 10}px`,
                            opacity:1,
                            backgroundColor: colors[defect.id_defect % colors.length]
                        }}>
                    </div>    

                ))}
                </div>

                <div className='filters'>
                    <h2>Filtros</h2>

                    {types.map((type, index) => (
                        <div key={index}>
                            <div>

                                <div className="button-r">
                                    <input key={index} type="checkbox" className="checkbox filter" value={`${type.id_defect}`} onChange={(e) => filt()}/>
                                    <div className="knobs"></div>
                                    <div className="layer"></div>
                                </div>

                                <h4>{type.name}</h4>
                                <div className='color' style={{backgroundColor: colors[type.id_defect % colors.length]}}></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
