import React, { useState } from 'react'
import { major } from '../../utils/constants';
import { fetchGet, setCookie } from '../../utils/functions';
import { TablePaginate } from './TablePaginate';
import { useNavigate } from 'react-router-dom';

export const TableOrders = () => {
    const [ orders, setOrders ] = useState([])  
    const navigate = useNavigate();  

    const getOrders = async (page, filter='') => {
        const pag = '?page=' + page;
        const { data, loading, error } = await fetchGet({
            endpoint: major.getOrders + pag,
            params:{"filter": filter}
        });
        setOrders(data.data.data.data);
        return data;
    };

    function cookieAndGo(name, cookieValue, route){
        setCookie({ name: name, value: cookieValue })
        navigate(route);
    }
    
    return (
        <TablePaginate
            rows={orders.map((order, index) => (
                <tr key={index}>
                    <td>{order.id}</td>
                    <td>{order.status}</td>
                    <td>{order.name} {order.paternal_surename} {order.maternal_surename}</td>
                    <td>{order.formatted_created_at}</td>

                    <td>
                        <button className='edit'  onClick={(e) =>cookieAndGo('order', order.id, '/admin/order')}>Editar</button>
                        {order.status == 1 && <button className='destroy' onClick={(e) =>navigate('/admin/order/pdf')}>PDF</button>}
                    </td>
                </tr>
            ))}

            columns={['Folio', 'Status', 'Entrenador', 'Fecha', 'Acciones']}
            functionData={getOrders}
        />
    )
}
