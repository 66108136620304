import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { TableOrders } from '../components/tables/TableOrders'
import { getCookie } from '../utils/functions';
import { NavbarTrainer } from '../components/navbars/NavbarTrainer';

export const AdminOrders = () => {
  const id = getCookie('id');


  return (
    <div>

        { id != 5 &&
          <NavbarAdmin/>
        }

        { id == 5 &&
          <NavbarTrainer/>
        }


        <TableOrders/>
    </div>
  )
}
