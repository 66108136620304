import React, { useEffect, useState } from 'react'
import { FormCreate } from './FormCreate'
import { clicked, fetchGet } from '../../utils/functions';
import { major, stullerDefault } from '../../utils/constants';
import { imagePreview } from '../../utils/functions/imagePreview';

export const FormCreateDefect = ({ updateTable }) => {
    const [ operations, setOperations ] = useState([]);
    const [ levels, setLevels ] = useState([]);
    const [ sublevels, setSublevels ] = useState([]);

    const [ operation, setOperation ] = useState('');
    const [ level, setLevel ] = useState('');
    const [ sublevel, setSublevel ] = useState('');
    const [ name, setName ] = useState('');
    const [ image, setImage ] = useState('');
    const [ preview, setPreview ] = useState('');
    const [ link, setLink ] = useState('');

    const getOperations = async () => {
        const { data } = await fetchGet({endpoint: major.operations});
        setOperations(data.data.data);
    };

    const getLevels = async (opt) => {
        const { data } = await fetchGet({endpoint: major.levels + opt});
        setLevels(data.data.data);
        setOperation(opt);
    };

    const getSublevels = async (lvl) => {
        const { data } = await fetchGet({endpoint: major.sublevels + lvl});
        setSublevels(data.data.data);
        setLevel(lvl);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(file);  
        const prev = imagePreview(event);
        setPreview(prev);   
    };

    useEffect(() => {
        getOperations();
    }, []);

    return (
        <FormCreate
            form={<>

                <img src={preview || stullerDefault}  onClick={() => clicked('image_defect_create')}/>
                <input id='image_defect_create' type='file' onChange={handleImageChange} style={{ display: 'none' }}/>

                <input placeholder='Nombre de defecto' value={name} onChange={(e) => (setName(e.target.value))}/>
                <input placeholder='Link' value={link} onChange={(e) => (setLink(e.target.value))}/>

            
                <select onChange={(e) => getLevels(e.target.value)} value={operation}>
                    <option value={0}>Selecciona una operación</option>
                    {operations.map((operation) => (<option key={operation.id} value={operation.id}>{operation.name}</option>))}
                </select>
            
                <select onChange={(e) => getSublevels(e.target.value)} value={level}>
                    <option value={0}>Selecciona un nivel</option>
                    {levels.map((level) => (<option key={level.id} value={level.id}>{level.name}</option>))}
                </select>
            
                <select onChange={(e) => setSublevel(e.target.value)} value={sublevel}>
                    <option value={0}>Selecciona un subnivel</option>
                    {sublevels.map((sublevel) => (<option key={sublevel.id} value={sublevel.id}>{sublevel.name}</option>))}
                </select>
            </>}

                params={{
                    "name": name,
                    "image": image,
                    "link": link,
                    "id_sublevel": sublevel
                }}

                headers={{"Content-Type" : "multipart/form-data"}}
                endpoint={major.createDefect}
                conditions={sublevel === '' || name === ''}
                updateTable={updateTable}

                resetForm={[setOperation, setLevel, setSublevel, setImage, setPreview, setName, setLink]}
        />
    )
}
