import React from 'react'
import { NavbarTrainer } from '../components/navbars/NavbarTrainer'
import { ViewTrainerItems } from '../components/views/ViewTrainerItems'

export const TrainerItems = () => {
  return (
    <div>
        <NavbarTrainer/>
        <ViewTrainerItems/>
    </div>
  )
}
