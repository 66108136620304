import React from 'react'
import { TablePaginate } from './TablePaginate';
import { baseURL } from '../../utils/constants/apis/major';
import { confirmCreate, fetchDelete, setCookie } from '../../utils/functions';
import { major } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

export const TableClasses = ({ data, updateTable }) => {
    const navigate = useNavigate();

    const destroyClass = async (classId) => {
        const { data, loading, error } = await fetchDelete({endpoint: major.destroyClass + classId})
        updateTable();
        return data;
      };
    
      const swalConfirm = (e, classId) => {
        confirmCreate('Confirmar', '¿Eliminar registro?').then((confirmed) => {if (confirmed){
            destroyClass(classId)
      }})}

    function cookieAndGo(name, cookieValie, route){
        setCookie({ name: name, value: cookieValie })
        navigate(route);
    }

      
    return (
        <TablePaginate
            rows={data.map((classs, index) => (
                <tr key={index}>
                    <td>{classs.title}</td>
                    <td><img src={baseURL + classs.image}/></td>
                    <td>{classs.file}</td>
                    <td>{classs.content}</td>
                    <td>{classs.pieces}</td>
                    <td>{classs.sequence}</td>

                    <td>
                        <button onClick={(e) =>cookieAndGo('classId', classs.id, '/admin/class/edit')} className='edit'>Editar</button>
                        <button onClick={(e) => swalConfirm(e, classs.id)} className='destroy'>Eliminar</button>
                    </td>
                </tr>
            ))}

            columns={['Títlulo', 'Imagen', 'Link', 'Contenido', 'Piezas', 'Secuencia', 'Acciones']}
            functionData={updateTable}
        />
    )
}
