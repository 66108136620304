import React from 'react'
import { NavbarTrainer } from '../components/navbars/NavbarTrainer'
import { TableAdminOrders } from '../components/tables/TableAdminOrders'

export const TrainerOrders = () => {
  return (
    <div>
        <NavbarTrainer/>
        <TableAdminOrders/>
    </div>
  )
}
