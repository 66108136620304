import React, { useEffect, useState } from 'react'
import { FormCreate } from './FormCreate';
import { major, stullerDefault } from '../../utils/constants';
import { clicked, fetchGet } from '../../utils/functions';
import { imagePreview } from '../../utils/functions/imagePreview';

export const FormCreateItem = ({ updateTable }) => {
    const [ items, setItems ] = useState('');
    const [ item, setItem ] = useState('');
    const [ pack, setPack ] = useState('');
    const [ name, setName ] = useState('');
    const [ brand, setBrand ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ image, setImage ] = useState('');
    const [ preview, setPreview ] = useState('');
    const [ minRange, setMinRange ] = useState('');
    const [ maxRange, setMaxRange ] = useState('');
    const [ operation, setOperation ] = useState('');
    const [ operations, setOperations ] = useState([]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(file);  
        const prev = imagePreview(event);
        setPreview(prev);   
    };

    const getOperations = async () => {
      const { data } = await fetchGet({endpoint: major.operations});
      setOperations(data.data.data);
    };

    useEffect(() => {
      getOperations();
    }, []);

    return (
      <FormCreate
        form={<>
            <img src={preview || stullerDefault} onClick={() => clicked('create-item-image')} />
            <input type="file" id='create-item-image' onChange={handleImageChange} style={{ display: 'none' }} />

            <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder='Nombre'/>
            <input value={brand} onChange={(e) => setBrand(e.target.value)} type="text" placeholder='Marca'/>
            <input value={item} onChange={(e) => setItem(e.target.value)} type="text" placeholder='Código'/>
            <input value={pack} onChange={(e) => setPack(e.target.value)} type="text" placeholder='Cantidad del paquete'/>
            <input value={description} onChange={(e) => setDescription(e.target.value)} type="text" placeholder='Descripción'/>

            <select value={operation} onChange={(e) => setOperation(e.target.value)}>
              <option value={0}>Seleccione una operación</option>
              {operations.map((opt) => (<option key={opt.id} value={opt.id}>{opt.name}</option>))}
            </select>

            <label>
              <span>Rango</span>
              <lable>
                <input value={minRange} onChange={(e) => setMinRange(e.target.value)} type="text" placeholder='Mínimo'/>
                <input value={maxRange} onChange={(e) => setMaxRange(e.target.value)} type="text" placeholder='Máximo'/>
              </lable>
            </label>
        </>}

        params={{
          "name": name,
          "brand": brand,
            "image": image,
            "number": item,
            "pack": pack,
            "description": description,
            "id_operation": operation,
            "min_size": minRange,
            "max_size": maxRange
        }}

        headers={{"Content-Type" : "multipart/form-data"}}
        endpoint={major.createItem}
        conditions={image === '' || item === '' || pack === '' || description === ''}
        updateTable={updateTable}

        resetForm={[setImage, setPreview, setItem, setPack, setDescription, setName, setBrand, setMinRange, setMaxRange]}
      />
    )
}
