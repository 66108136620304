import React, { useEffect, useState } from 'react'
import { fetchGet, getCookie, setCookie } from '../../utils/functions';
import { major } from '../../utils/constants';
import { TablePaginate } from './TablePaginate';
import { useNavigate } from 'react-router-dom';

export const TableAdminOrders = () => {
    const [ orders, setOrders ] = useState([]);
    const user =  getCookie('id');
    const navigate = useNavigate();

    const getOrders = async (page, filter='') => {
        const pag = '?page=' + page;
        const { data, loading, error } = await fetchGet({
            endpoint: major.getOrdersByTrainer + user  + pag,
            params:{"filter": filter}
        });
        setOrders(data.data.data.data);
        return data;
    };

    function cookieAndGo(name, cookieValue, route){
        setCookie({ name: name, value: cookieValue })
        navigate(route);
    }

    useEffect(() => {getOrders(1);}, []);


    return (
        <TablePaginate
            rows={orders.map((order, index) => (
                <tr key={index}>
                    <td>{order.id}</td>

                    <td><button className='edit' onClick={(e) =>cookieAndGo('order', order.id, '/trainer/orders/edit')}>Editar</button></td>
                </tr>
            ))}

            columns={['Órden', 'Acciones']}
            functionData={getOrders}
        />
    )
}
