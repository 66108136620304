import React, { useState } from 'react'
import { TableClasses } from '../tables/TableClasses'
import { fetchGet, getCookie } from '../../utils/functions';
import { major } from '../../utils/constants';
import { FormCreateClass } from '../forms/FormCreateClass';

export const ViewAdminClasses = () => {
    const [ classes, setClasses ] = useState([])    
    const contentId = getCookie('contentId');

    const getClasses = async (page, filter='') => {
        const pag = '?page=' + page;
        const { data, loading, error } = await fetchGet({
            endpoint: major.classes + contentId  + pag,
            params:{"filter": filter}
        });
        setClasses(data.data.data.data);
        return data;
    };

  return (
    <div className='flex-container'>
        <FormCreateClass updateTable={getClasses}/>
        <TableClasses data={classes} updateTable={getClasses}/>
    </div>
  )
}
