import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { closeSesion, fetchGet, getCookie } from '../../utils/functions';
import { awards, background, cart, home, major, piecesList, serve, stullerDefault, userList, users } from '../../utils/constants';
import { UserOptions } from './UserOptions';

export const NavbarTrainer = () => {
    const [ name, setName ] = useState('');
    const [ image, setImage ] = useState('');
    const navigate  = useNavigate();
    const id = getCookie('id');

    const validate = async () => {
        const { data, loading, error } = await fetchGet({endpoint: major.validateUser + id});
        if(data.data.data.role != 200){navigate('/')}
        setName(data.data.data.name);
        setImage(data.data.data.image);
    };

    useEffect(() => {
        if(typeof(id) == 'undefined'){
            navigate('/')
        }
        if(typeof(id) == 'string'){
            validate();
        }

        //if(id == 2){
        //    document.body.style.backgroundImage = `url(https://www.lavanguardia.com/files/og_thumbnail/uploads/2017/09/05/5fa3db865beb2.jpeg)`;
        //}else{
        //    document.body.style.backgroundImage = `url(${background})`;
        //}
        
    }, []);

    return (
      <nav>
          <div>
              <div className='logos'>
                  <img src={serve}/>
                  <p>MAESTRO JOYERO ORFEBRE</p>
              </div>
  
              <div className='buttons'>
                <div onClick={() => navigate('/trainer/recognitions')} className='info views'><img src={awards} className='invert-color'/><p>Reconocimientos</p></div>
                <div onClick={() => navigate('/trainer/orders')} className='info views'><img src={piecesList} className='invert-color'/><p>Pedidos</p></div>


                { id == 5 &&
                    <div onClick={() => navigate('/admin/orders')} className='info views'><img src={cart} className='invert-color'/>
                        <p>Órdenes</p>
                    </div>
                }



                <div onClick={() => navigate('/trainer/items')} className='info views'><img src={cart} className='invert-color'/><p>Herramientas</p></div>
                <div onClick={() => navigate('/trainer/list')} className='info views'><img src={userList} className='invert-color'/><p>Asistencia</p></div>
                <div onClick={() => navigate('/trainer/users')} className='info views'><img src={users} className='invert-color'/><p>Usuarios</p></div>
                <div onClick={() => navigate('/trainer/')} className='info views'><img src={home} className='invert-color'/><p>Inicio</p></div>
                <UserOptions image={image} name={name}/>
              </div>
          </div>
      </nav>
    )
}
