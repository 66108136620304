import React, { useState } from 'react'
import { FormCreate } from './FormCreate'
import { major } from '../../utils/constants';

export const FormCreateNew = ({ updateTable }) => {
    const [ link, setLink ] = useState('');

    return (
      <FormCreate
              form={<>
                  <label>
                      <input value={link} placeholder='Link' onChange={(e) => setLink(e.target.value)}/>
                  </label>
              </>}

          params={{
              "link": link
          }}

        endpoint={major.createScoop}
        conditions={link === ''}
        updateTable={updateTable}

        resetForm={[setLink]}

      />
    )
}
