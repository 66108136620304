import React from 'react'
import { NavbarTrainer } from '../components/navbars/NavbarTrainer'
import { TableListTrainer } from '../components/tables/TableListTrainer'

export const CurrentListTrainer = () => {
  return (
    <div>
        <NavbarTrainer/>
        <TableListTrainer/>
    </div>
  )
}
