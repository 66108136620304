let myLandbot;

export function loadLandbotScript() {
    if(!window.Landbot){
        var script = document.createElement('script');
        script.src = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js';
        script.onload = initializeLandbot;
        document.head.appendChild(script);
    }
}

//export function initializeLandbot() {
//    if (typeof window.Landbot !== 'undefined') {
//        var myLandbot = new window.Landbot.Livechat({
//            configUrl: 'https://storage.googleapis.com/landbot.online/v3/H-1588456-YW56EZ9QBTKSBMR6/index.json'
//        });
//    }
//}

export function initializeLandbot() {
    if (typeof window.Landbot !== 'undefined') {
        myLandbot = new window.Landbot.Livechat({
            configUrl: 'https://storage.googleapis.com/landbot.online/v3/H-1588456-YW56EZ9QBTKSBMR6/index.json'
        });
    }
}

export function cleanUpLandbot() {
    if (myLandbot) {
        myLandbot.destroy();
        myLandbot = null;
    }

    const landbotScript = document.querySelector('script[src="https://cdn.landbot.io/landbot-3/landbot-3.0.0.js"]');
    if (landbotScript) {
        landbotScript.parentNode.removeChild(landbotScript);
    }
}
