import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { fetchGet, fetchPost, getCookie } from '../../utils/functions';
import { major } from '../../utils/constants';

export const IframeClassWatch = () => {
    const [file, setFile] = useState([]);
    const location = useLocation();
    const classId = location.state?.variable || null;
    const userId = getCookie('id');


    const getFile = async (e) =>{
        const { data, loading, error } = await fetchGet({ endpoint: major.userClassFile + classId});
        setFile(data.data.data.file);
    };

    const getNextContent = async (id_class) =>{ 
         const { data, loading, error } = await fetchGet({ endpoint: major.nextContent + id_class});
         unlockContent(data.data.data.id, userId);
    };

    const getNextClass = async (e) =>{ 
        const { data, loading, error } = await fetchGet({ endpoint: major.nextClass + classId});
        if(data.data.data){
            unlockClass(data.data.data.id, userId);
        }else{
            getNextContent(classId);
        }
    };

    const unlockContent = async (id_content, id_user) =>{ 
        const { data, loading, error } = await fetchPost({
            endpoint: major.userUnlockContent,
            params:{
                "id_user": id_user,
                "id_content": id_content
            },
            notification: false
        });
    };

    const unlockClass = async (id_class, id_user) =>{ 
        const { data, loading, error } = await fetchPost({
            endpoint: major.userUnlockClass,
            params:{
                "id_user": id_user,
                "id_class": id_class
            }});
    };

    useEffect(() => {
        getFile(); 
        if(classId != null){
            getNextClass();
        }
    }, []);


    return (
        <div className='iframe'>
            <iframe src={file}></iframe>
        </div>
    )
}
