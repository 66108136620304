import React, { useEffect, useState } from 'react'
import { major, stullerDefault } from '../../utils/constants'
import { clicked, fetchGet, fetchPut, getCookie } from '../../utils/functions'
import { imagePreview } from '../../utils/functions/imagePreview';

export const FormEditRecognitionPiece = () => {
    const piece = getCookie('recognition_piece');

    const [ image, setImage ] = useState('');
    const [ preview, setPreview ] = useState('');
    const [ name, setName ] = useState('');
    const [ link, setLink ] = useState('');

    const getPiece = async () => {
        const { data } = await fetchGet({endpoint: major.getRecognitionPieceForEdit + piece});
        setName(data.data.data.name);
        setLink(data.data.data.preview);
        setImage(data.data.data.image);
        if(data.data.data.image){setPreview(major.baseURL + data.data.data.image);}
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(file);  
        const prev = imagePreview(event);
        setPreview(prev);   
    };

    const FormUpdatePiece = async (e) => {
        e.preventDefault();
    
        const { data, loading, error } = 
        await fetchPut({
            endpoint: major.updateRecognitionPiece + piece,
            params:{
              "name": name,
              "preview": link,
              "image": image
            },
            headers:{"Content-Type" : "multipart/form-data"}
        });
      };
    

    useEffect(() => {
        getPiece();
    }, []);

    return (
      <div className='form-flex'>    
          <form >
            <h2>EDITAR PIEZA</h2>

            <img src={preview || stullerDefault} onClick={() => clicked('edit-recognition-piece-image')} />
            <input type="file" id='edit-recognition-piece-image' onChange={handleImageChange} style={{ display: 'none' }} />

            <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder='Nombre'/>
            <input value={link} onChange={(e) => setLink(e.target.value)} type="text" placeholder='Link de vista previa'/>


            <button type='submit' onClick={(e) => FormUpdatePiece(e)}>Editar</button>
          </form>
      </div>
    )
}
