import React from 'react'
import { NavbarTrainer } from '../components/navbars/NavbarTrainer'
import { GridDistribution } from '../components/grids/GridDistribution'

export const TrainerDistribution = () => {
  return (
    <div>
        <NavbarTrainer/>
        <GridDistribution/>
    </div>
  )
}
