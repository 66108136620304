import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { FormEditItem } from '../components/forms/FormEditItem'

export const EditItem = () => {
  return (
    <div>
        <NavbarAdmin/>
        <FormEditItem/>
    </div>
  )
}
