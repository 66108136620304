import React, { useEffect, useState } from 'react'
import { major, stullerDefault } from '../../utils/constants';
import { clicked, confirmCreateUser, fetchGet, fetchPost, toastComplete } from '../../utils/functions';
import { imagePreview } from '../../utils/functions/imagePreview';
import { useUser } from '../../utils/hooks';
import { FormCreate } from './FormCreate';

export const FormCreateUser = ({ updateTable }) => {
    const { name, handleName } = useUser();
    const { paternalSurename, handlePaternalSurename } = useUser();
    const { maternalSurename, handleMaternalSurename } = useUser();
    const { birthdate, handleBirthdate } = useUser();
    const { image, handleImage } = useUser();
    const { courses, handleCourses } = useUser();
    const [ preview, setPreview ] = useState('');
    const [ course, setCourse ] = useState('');
    const [ gallery, setGallery ] = useState('');
    const [ admission, setAdmission ] = useState('');
    const [ isTrainer, setIsTrainer ] = useState(false);


    const getCurrentCourses = async () => {
        const { data } = await fetchGet({endpoint: major.currentCourses});
        handleCourses(data.data.data);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        handleImage(file);  
        const prev = imagePreview(event);
        setPreview(prev);   
    };

    useEffect(() => {getCurrentCourses()}, []);

    return (
        <FormCreate
            form={<>
                <img src={preview || stullerDefault} onClick={() => clicked('create-user-image')} />
                <input type="file" id='create-user-image' onChange={handleImageChange} style={{ display: 'none' }} />
                
                <input value={name} onChange={(e) => handleName(e.target.value)} type="text" placeholder='Nombre(s)'/>
                <input value={paternalSurename} onChange={(e) => handlePaternalSurename(e.target.value)} type="text" placeholder='Apellido Paterno'/>
                <input value={maternalSurename} onChange={(e) => handleMaternalSurename(e.target.value)} type="text" placeholder='Apellido Materno'/>
                <label><span>Fecha:</span> <input value={birthdate} onChange={(e) => handleBirthdate(e.target.value)}  type="date" /></label>
                <label><span>Ingreso:</span> <input value={admission} onChange={(e) => setAdmission(e.target.value)}  type="date" /></label>
                
                <select onChange={(e) => setCourse(e.target.value)} value={course}>
                    <option value={0}>Seleccione un curso</option>
                    {courses.map((course) => (<option key={course.id} value={course.id}>{course.operation} {course.sublevel} {course.start} - {course.end}</option>))}
                </select>

                <input value={gallery} onChange={(e) => setGallery(e.target.value)} type="text" placeholder='Galeria'/>
            </>}

            params={{
              "name": name,
              "paternal_surename": paternalSurename,
              "maternal_surename": maternalSurename,
              "image": image,
              "birthdate": birthdate,
              "gallery": gallery,
              "id_course": course,
              "is_trainer": isTrainer,
              "admission": admission
            }}
        
            headers={{"Content-Type" : "multipart/form-data"}}
            endpoint={major.createuser}
            conditions={name === '' || paternalSurename === '' || maternalSurename === ''}
            updateTable={updateTable}

            resetForm={[handleName, handlePaternalSurename, handleMaternalSurename, handleBirthdate, handleImage, setPreview, setCourse, setGallery, setAdmission]}
        />
    )
}
