import React from 'react'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'
import { GridDistribution } from '../components/grids/GridDistribution'

export const AdminDistribution = () => {
  return (
    <div>
        <NavbarAdmin/>
        <GridDistribution/>
    </div>
  )
}
