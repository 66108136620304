import React from 'react'
import { TableRecognitions } from '../components/tables/TableRecognitions'
import { NavbarAdmin } from '../components/navbars/NavbarAdmin'

export const AdminRecognitions = () => {
  return (
    <div>
        <NavbarAdmin/>
        <TableRecognitions/>
    </div>
  )
}
