import React from 'react'
import { FormLogin } from '../components/forms/FormLogin';
import { NavbarMenuB } from '../components/navbars/NavbarMenuB';
import { PageStats } from '../components/charts/PageStats';

export const Login = () => {

  return (
    <div>
      <NavbarMenuB/>
      <PageStats/>
      <FormLogin/>
    </div>
  );
};