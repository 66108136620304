import React, { useEffect, useState } from 'react'
import { fetchDelete, fetchGet, fetchPost, getCookie, modal } from '../../utils/functions';
import { eye, major, stullerDefault } from '../../utils/constants';
import { ModalOptions } from '../modals/ModalOptions';
import { baseURL } from '../../utils/constants/apis/major';
import Swal from 'sweetalert2';
import { IframeWatchLink } from '../iframes/IframeWatchLink';

export const FormSelectDefectPiece = () => {
    const userId = getCookie('userId');
    const [ name, setName ] = useState('');

    const [ idCourse, setIdCourse ] = useState('');
    const [ contents, setContents ] = useState([]);
    const [ classes, setClasses ] = useState([]);
    const [ pieces, setPieces ] = useState([]);
    const [ defects, setDefects ] = useState([]);

    const [ imageClass, setImageClass ] = useState(null);
    const [ idRecognition, setIdRecognition ] = useState('');
    const [ piece, setPiece ] = useState('');
    const [ idDefect, setIdDefect ] = useState(0);
    const [ imageContent, setImageContent ] = useState(null);
    const [ imageDefect, setImageDefect ] = useState(null);


    const [ xAxis, setXAxis ] = useState(null);
    const [ yAxis, setYAxis ] = useState(null);
    const [ points, setPoints ] = useState([]);

    const [ watching, setWatching ] = useState('');
    const [ show, setShow ] = useState(false);


    const getUser = async () => {
        const { data } = await fetchGet({endpoint: major.TrainerUser + userId});
        let fullName = data.data.data.name + ' ' + data.data.data.paternal_surename + ' ' + data.data.data.maternal_surename;
        setName(fullName);
        setIdCourse(data.data.data.id_course);

        getContents(data.data.data.id_sublevel);
        getDefects(data.data.data.id_sublevel);
    };

    const getDefects = async (sublevel) => {
        const { data } = await fetchGet({endpoint: major.getSublevelDefects + sublevel});
        setDefects(data.data.data);
        setIdDefect(data.data.data[0].id);
        setImageDefect(baseURL + data.data.data[0].image)
    };

    const getContents = async (sublevel) => {
        const { data } = await fetchGet({endpoint: major.getContentsUserByTrainer + sublevel});
        setContents(data.data.data);
    };

    const getRecognitions = async (content, image) => {
        setImageContent(baseURL + image);
        const { data } = await fetchGet({endpoint: major.getClassesUserByTrainer + content});

        let classes = [];

        for (let item of data.data.data) {
            let hide = await validateClase(item.lot, item.id_recognition);
            let obj = { id: item.id, image: item.image, name: item.name, hide: hide };
            classes.push(obj);
        }

        setClasses(classes);
        modal('classes_trainer');
    };
    
    const getPieces = async (content, image) => {
        setIdRecognition(content);
        setImageClass(baseURL + image);

        const { data } = await fetchGet({endpoint: major.getLot + content});

        let lot = data.data.data.lot;
        let pcs = [];

        for (let index = 1; index <= lot; index++) {
            let hide = await validatePiece(index, content);
            let obj = { id: index, image: null, title: 'Pieza ' + index, hide: hide };
            pcs.push(obj);
        }

        setPieces(pcs);
        modal('pieces_trainer');
    };

    const validatePiece = async (pz, rec) => {
        const { data } = await fetchGet({endpoint: major.validatePiece + userId,
        params: {
            'piece': pz,
            'id_user': userId,
            'id_recognition': rec
        },
        notification: false
    });
        return data.data.data;
    };

    const validateClase = async (count, rec) => {
        const { data } = await fetchGet({endpoint: major.validateClase + userId,
        params: {
            'count': count,
            'id_recognition': rec
        },
        notification: false
    });
        return data.data.data;
    };

    const isThereDefect = async (pz) => {
        setPiece(pz);
        getPoints(pz);
        
        Swal.fire({
            icon: 'info',
            title: '¿Contiene defecto?',
            text: '¿La pieza contiene uno o más defectos?',
            toast: true,
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No', 
        }).then((result) => {
            if (result.isConfirmed) {
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                setIdDefect(0);
                FormCreate({
                    pz: pz,
                    defect: 0,
                });
                
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        });
    };

    const getPoints = async (pz = piece) => {
        const { data } = await fetchGet({endpoint: major.getPoints + idRecognition + '/' + pz});
        setPoints(data.data.data);
    };

    function finish(value, image){
        setImageDefect(baseURL + image)
        setIdDefect(value);
    };

    const FormCreate = async ({pz=piece, defect=idDefect}) => {
        const { data, loading, error } =  await fetchPost({ endpoint: major.createPieceDefect,
            params:{
                "id_user": userId,
                "id_course": idCourse,
                "id_recognition": idRecognition,
                "piece": pz,
                "id_defect": defect,
                "x_axis": xAxis,
                "y_axis": yAxis
            }
        });

        getPoints();
    };

    function add(){
        if(idDefect == 0){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Selecciona un defecto',
                toast: true,
            })
        }else{
            FormCreate({
                pz: piece,
                defect: idDefect
            });
        }
    }

    function send(){
        if(idDefect == 0){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Selecciona un defecto',
                toast: true,
            })
        }else{
            FormCreate({
                pz: piece,
                defect: idDefect
            });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////
    
    function changeDefect(id, image, watch){
        setIdDefect(id);
        setImageDefect(baseURL + image);
        setWatching(watch);
    }

    function showIframe(){
        setShow(!show);
    }

    const deletePoints = async (e) => {
        e.preventDefault();
        const { data } = await fetchDelete({endpoint: major.deletePoints + idRecognition + '/' + piece});
        getPoints();
    };

    const handleClick = (e) => {
        // Obtener el elemento y sus propiedades para calcular las coordenadas relativas
        const element = e.currentTarget; // Usar currentTarget para referirse al elemento al que se le añadió el event listener
        const rect = element.getBoundingClientRect();
        
        // Calcular coordenadas relativas al elemento
        const x = Math.round(e.clientX - rect.left);
        const y = Math.round(e.clientY - rect.top);

        setXAxis(x);
        setYAxis(y);
    };



    //////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        getUser();
    }, []);

    return (
        <>
            <div className='select-defect-component'>
                <div className='bar'>
                    <div className='user'> Calificar a {name} </div>
        
                    <div className='selects'>
                        <div onClick={() => modal('contents_trainer')}>
                            <h2>Contenido</h2>
                            <img  src={imageContent ? imageContent : stullerDefault}/>
                        </div>

                        <div onClick={() => modal('classes_trainer')}>
                            <h2>Clase</h2>
                            <img  src={imageClass ? imageClass : stullerDefault}/>
                        </div>

                        <div onClick={() => modal('pieces_trainer')}>
                            <h2>Pieza</h2>
                            <span>{piece}</span>
                        </div>
                    </div>
                </div>

                { /*Apartado para crear el select de los defectos*/ }

                <div className='content'>
                    <div className='select-defect'>
                        <select onChange={(e) => {
                            const selectedDefectId = e.target.value;
                            const selectedDefect = defects.find(defect => defect.id.toString() === selectedDefectId);
                            const selectedDefectImage = selectedDefect ? selectedDefect.image : null;
                            const selectedDefectWatch = selectedDefect ? selectedDefect.watch : null;
                            changeDefect(selectedDefectId, selectedDefectImage, selectedDefectWatch);
                            }}>
                            {defects.map((defect) => (
                                <option key={defect.id} value={defect.id}>{defect.name}</option>
                            ))}
                        </select>

                        <img  src={imageDefect ? imageDefect : stullerDefault}/>
                        {watching ? <div className='eye' onClick={() => showIframe()}><img src={eye}/></div> : ''}
                    </div>

                    <div className='image-coords'>
                        <div className='buttons'>
                            <button id='send-defect-piece' className='edit' onClick={(e) => add()}>Agregar</button>
                            <button onClick={(e) => send(e)}>Enviar</button>
                            <button className='destroy' onClick={(e) => deletePoints(e)}>Limpiar</button>
                        </div>

                        <div className='image' onClick={handleClick}>
                            <img  src={imageClass ? imageClass : stullerDefault}/>
                            <div className='pointer-current' style={{ top: yAxis - 5, left: xAxis - 5, display: (xAxis == null || yAxis == null) ? 'none' : 'block'}} ></div>

                            {points.map((point, index) => (
                                <div className='pointer' key={index} style={{ top: point.y_axis - 5, left: point.x_axis - 5}}></div>
                            ))}
                        </div>
                    </div>
                </div>
                
            </div>

            <ModalOptions id='contents_trainer' data={contents} title='Seleccionar contenido' callFunction={getRecognitions}/>
            <ModalOptions id='classes_trainer' data={classes} title='Seleccionar clase' callFunction={getPieces}/>
            <ModalOptions id='pieces_trainer' data={pieces} title='Seleccionar pieza' callFunction={isThereDefect}/>
            <ModalOptions id='defects_trainer' data={defects} title='Seleccionar defecto' callFunction={finish} watch={true}/>
            <IframeWatchLink link={watching} state={show} close={showIframe}/>
        </>
    )
}
