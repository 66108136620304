import React from 'react'
import { NavbarMenuC } from '../components/navbars/NavbarMenuC'
import { FormLoginMajor } from '../components/forms/FormLoginMajor'
import { PageStats } from '../components/charts/PageStats'

export const Major = () => {
  return (
    <div>
        <NavbarMenuC/>
        <PageStats/>
        <FormLoginMajor/>
    </div>
  )
}
